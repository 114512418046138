import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  shuffle
} from '../../../../utils/random';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import { View } from 'react-native';
import { countRange, filledArray, range } from '../../../../utils/collections';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import { getRandomKs1Name, ks1NameSchema } from '../../../../utils/names';
import { numberEnum } from '../../../../utils/zod';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { isEqual } from '../../../../utils/matchers';
import { compareFractions, portionToText } from '../../../../utils/fractions';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';
import { MarkupAssets } from '../../../../markup';

////
// Questions
////

const svgNames = (
  shape: 'Circle' | 'Square' | 'Rectangle' | 'Triangle',
  denominator: 2 | 3 | 4,
  color: 'blue' | 'green' | 'yellow'
): SvgName[] => {
  if (shape !== 'Rectangle') {
    switch (denominator) {
      case 2: {
        return [
          `Equal_shapes_2_parts/${shape}_equal_2-0_1`,
          `Equal_shapes_2_parts/${shape}_equal_2-1_1_${color}`,
          `Equal_shapes_2_parts/${shape}_equal_2-2_1_${color}`
        ];
      }
      case 3:
        return [
          `Equal_shapes_3_parts/${shape}_equal_3-0_1`,
          `Equal_shapes_3_parts/${shape}_equal_3-1_1_${color}`,
          `Equal_shapes_3_parts/${shape}_equal_3-2_1_${color}`,
          `Equal_shapes_3_parts/${shape}_equal_3-3_1_${color}`
        ];
      case 4: {
        if (shape === 'Triangle') {
          throw new Error(`There is no svg for a triangle split into 4`);
        }
        return [
          `Equal_shapes_4_parts/${shape}_equal_4-0_1`,
          `Equal_shapes_4_parts/${shape}_equal_4-1_1_${color}`,
          `Equal_shapes_4_parts/${shape}_equal_4-2_1_${color}`,
          `Equal_shapes_4_parts/${shape}_equal_4-3_1_${color}`,
          `Equal_shapes_4_parts/${shape}_equal_4-4_1_${color}`
        ];
      }
    }
  } else {
    switch (denominator) {
      case 2: {
        return [
          `Equal_shapes_2_parts/Rectangle_equal_2-0_2`,
          `Equal_shapes_2_parts/Rectangle_equal_2-1_2_${color}`,
          `Equal_shapes_2_parts/Rectangle_equal_2-2_2_${color}`
        ];
      }
      case 3:
        return [
          `Equal_shapes_3_parts/${shape}_equal_3-0_1`,
          `Equal_shapes_3_parts/${shape}_equal_3-1_1_${color}`,
          `Equal_shapes_3_parts/${shape}_equal_3-2_1_${color}`,
          `Equal_shapes_3_parts/${shape}_equal_3-3_1_${color}`
        ];
      case 4:
        return [
          `Equal_shapes_4_parts/Rectangle_equal_4-0_2`,
          `Equal_shapes_4_parts/Rectangle_equal_4-1_2_${color}`,
          `Equal_shapes_4_parts/Rectangle_equal_4-2_2_${color}`,
          `Equal_shapes_4_parts/Rectangle_equal_4-3_2_${color}`,
          `Equal_shapes_4_parts/Rectangle_equal_4-4_2_${color}`
        ];
    }
  }
};

const Question1 = newQuestionContent({
  uid: 'bkO',
  description: 'bkO',
  keywords: ['Whole', 'Part', 'Count', 'Fraction'],
  schema: z
    .object({
      denominator: numberEnum([2, 3, 4]),
      shape: z.enum(['Circle', 'Square', 'Rectangle', 'Triangle']),
      color: z.enum(['blue', 'yellow', 'green']),
      bothNumAndDenomAnswerBoxes: z.boolean()
    })
    .refine(
      val => (val.denominator === 4 && val.shape !== 'Triangle') || true,
      'When shape is Triangle we should not have 4 as denominator'
    ),
  simpleGenerator: () => {
    const denominator = getRandomFromArray([2, 3, 4] as const);
    return {
      denominator,
      color: getRandomFromArray(['blue', 'yellow', 'green'] as const),
      bothNumAndDenomAnswerBoxes: getRandomBoolean(),
      shape: getRandomFromArray(
        denominator === 4
          ? (['Circle', 'Square', 'Rectangle'] as const)
          : (['Circle', 'Square', 'Rectangle', 'Triangle'] as const)
      )
    };
  },
  Component: ({ question, translate, displayMode }) => {
    const { denominator, color, bothNumAndDenomAnswerBoxes, shape } = question;

    const svgs = svgNames(shape, denominator, color);

    const imageWidth =
      displayMode === 'digital' ? Math.max(180 - (denominator - 2) * 30, 120) : 200;

    return (
      <QF1ContentAndSentences
        title={translate.ks1Instructions.completeTheFractionsShownInThePattern()}
        fractionContainerStyle={{ height: displayMode === 'digital' ? 96 : 150 }}
        Content={({ dimens }) => (
          <View
            style={{ flexDirection: 'row', justifyContent: 'space-evenly', width: dimens.width }}
          >
            {svgs.map((val, i) => (
              <AssetSvg key={i} name={val} width={imageWidth} />
            ))}
          </View>
        )}
        pdfDirection="column"
        style={{ flexDirection: 'row', justifyContent: 'space-evenly' }}
        pdfSentenceStyle={{ flexDirection: 'row', justifyContent: 'space-evenly' }}
        sentenceStyle={{
          width: imageWidth,
          height: displayMode === 'digital' ? 210 : 400,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        textStyle={{ textAlign: 'center', lineHeight: displayMode === 'digital' ? 210 : 400 }}
        inputMaxCharacters={1}
        sentences={countRange(denominator + 1).map(val =>
          val === 0
            ? (0).toLocaleString()
            : bothNumAndDenomAnswerBoxes
            ? `<frac nAns='' dAns='' />`
            : `<frac nAns='' d='${denominator}' />`
        )}
        testCorrect={userAnswer =>
          bothNumAndDenomAnswerBoxes
            ? countRange(denominator + 1).every(val =>
                val === 0 ? true : compareFractions(userAnswer[val], [val, denominator])
              )
            : isEqual(countRange(denominator + 1).map(val => (val === 0 ? [] : [val.toString()])))(
                userAnswer
              )
        }
        customMarkSchemeAnswer={{
          answersToDisplay: bothNumAndDenomAnswerBoxes
            ? countRange(denominator + 1).map(val =>
                val === 0 ? [] : [val.toLocaleString(), denominator.toLocaleString()]
              )
            : countRange(denominator + 1).map(val => (val === 0 ? [] : [val.toLocaleString()])),
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'bkP',
  description: 'bkP',
  keywords: ['Whole', 'Part', 'Fraction'],
  schema: z
    .object({
      denominator: numberEnum([2, 3, 4]),
      shape: z.enum(['Circle', 'Square', 'Rectangle', 'Triangle']),
      color: z.enum(['blue', 'yellow', 'green']),
      missingIndex: z.number().int().min(0),
      rotations: numberEnum([0, 180]).array().length(4),
      items: z.number().int().min(0).max(4).array()
    })
    .refine(val => val.missingIndex <= val.denominator)
    .refine(val => val.items.every(z => z <= val.denominator))
    .refine(
      val => (val.denominator === 4 && val.shape !== 'Triangle') || val.denominator <= 4,
      'When shape is Triangle we should not have 4 as denominator'
    ),
  simpleGenerator: () => {
    const denominator = getRandomFromArray([2, 3, 4] as const);
    const shape = getRandomFromArray(
      denominator === 4
        ? (['Circle', 'Square', 'Rectangle'] as const)
        : (['Circle', 'Square', 'Rectangle', 'Triangle'] as const)
    );
    return {
      denominator,
      color: getRandomFromArray(['blue', 'yellow', 'green'] as const),
      shape,
      missingIndex:
        // if denominator of 2 then make the missing one go in the middle. This is so we have no ambiguity.
        denominator === 2 ? 1 : randomIntegerInclusive(denominator === 4 ? 1 : 0, denominator),
      rotations:
        shape === 'Triangle'
          ? filledArray(0 as const, 4)
          : countRange(4).map(() => getRandomFromArray([0, 180] as const)),
      items: shuffle(denominator === 4 ? countRange(4, 1) : countRange(denominator + 1))
    };
  },
  Component: ({ question, translate }) => {
    const { denominator, color, missingIndex, shape, rotations, items } = question;

    const svgs = svgNames(shape, denominator, color).map((val, i) => (
      <AssetSvg key={i} name={val} height={120} width={120} />
    ));

    const array = denominator === 4 ? countRange(4, 1) : countRange(denominator + 1);

    return (
      <MarkupAssets
        elements={{
          shape0: svgs[0],
          shape1: svgs[1],
          shape2: svgs[2],
          shape3: svgs[3],
          shape4: svgs[4]
        }}
      >
        <QF37SentenceDrag
          title={translate.ks1Instructions.dragTheMissingFractionIntoThePattern()}
          pdfTitle={translate.ks1PDFInstructions.useTheCardsToCompleteTheFractionPattern()}
          items={items.map(id => ({
            value: id,
            component: (
              <View style={{ transform: `rotate(${id === missingIndex ? 0 : rotations[id]}deg)` }}>
                {svgs[id]}
              </View>
            )
          }))}
          itemVariant="pdfSquare"
          actionPanelVariant="bottomMid"
          sentence={`${array
            .map(val => (missingIndex === val ? '<ans/>' : `<asset name="shape${val}" />`))
            .join('  ')}`}
          testCorrect={[missingIndex]}
        />
      </MarkupAssets>
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bkQ',
  description: 'bkQ',
  keywords: ['Whole', 'Part', 'Count', 'Fraction'],
  schema: z
    .object({
      missingIndexes: z.array(z.number().int().min(0).max(1).or(z.null())),
      denominator: z.number().int().min(2).max(4),
      name: ks1NameSchema
    })
    .refine(val => val.missingIndexes.filter(val => val !== null).length <= val.denominator),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(2, 4);
    const missingIndex = randomUniqueIntegersInclusive(1, denominator, Math.min(3, denominator));

    return {
      denominator,
      missingIndexes: rejectionSample(
        () =>
          countRange(denominator, 1).map(val =>
            missingIndex.includes(val) ? randomIntegerInclusive(0, 1) : null
          ),
        val => val.includes(0) && val.includes(1)
      ),
      name: getRandomKs1Name()
    };
  },
  Component: ({ question, translate }) => {
    const { denominator, missingIndexes, name } = question;

    const fractions = range(1, denominator).map(val => [val, denominator]);

    const fractionToWords = portionToText(denominator, translate);

    return (
      <QF2AnswerBoxOneSentence
        title={translate.ks1Instructions.nameIsCountingInXFillInTheMissingNumbers(
          name,
          fractionToWords
        )}
        fractionContainerStyle={{ height: 96 }}
        sentence={`${(0).toLocaleString()}, ${fractions
          .map(
            (arr, i) =>
              `<frac ${missingIndexes[i] === 0 ? `nAns=''` : `n='${arr[0]}'`}  ${
                missingIndexes[i] === 1 ? `dAns=''` : `d='${arr[1]}'`
              } />, `
          )
          .join('')}`}
        testCorrect={fractions
          .map((frac, fracId) => frac.filter((_, i) => missingIndexes[fracId] === i))
          .filter(val => val.length !== 0)
          .map(val => val.toString())}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CountInFractionsUpToAWhole',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question3]
});
export default SmallStep;
