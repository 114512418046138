import z from 'zod';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { getRandomFromArray, randomIntegerInclusive } from '../../../../utils/random';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { ArrayOfObjects } from '../../../../components/question/representations/ArrayOfObjects';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import { ArrayOfObjectsColors } from '../../../../theme/colors';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import QF29CreateArray from '../../../../components/question/questionFormats/QF29CreateArray';
import { MULT } from '../../../../constants';
import {
  arraysHaveSameContents,
  arraysHaveSameContentsUnordered
} from '../../../../utils/collections';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'biS',
  description: 'biS',
  keywords: ['Array', 'Repeated addition', 'Multiplication'],
  schema: z.object({
    rows: z.number().int().min(2).max(5),
    columns: z.number().int().min(2).max(6),
    item: z.enum([
      'Array_objects/AppleRed',
      'Array_objects/AppleGreen',
      'Circles/circle_blue',
      'Circles/circle_red',
      'Circles/circle_yellow',
      'Circles/circle_green',
      'Cubes_blank/Coloured_cube_unlabelled_blue',
      'Cubes_blank/Coloured_cube_unlabelled_purple',
      'Cubes_blank/Coloured_cube_unlabelled_orange',
      'Cubes_blank/Coloured_cube_unlabelled_red',
      'Cubes_blank/Coloured_cube_unlabelled_green',
      'Cubes_blank/Coloured_cube_unlabelled_yellow',
      'Marble',
      'Sweets/SweetPink',
      'Sweets/SweetRed',
      'Sweets/SweetGreen',
      'Sweets/SweetBlue'
    ])
  }),
  simpleGenerator: () => {
    const rows = randomIntegerInclusive(2, 5);
    const columns = randomIntegerInclusive(2, 6);

    const itemName = getRandomFromArray(['Apple', 'Marble', 'Counter', 'Cube', 'Sweet'] as const);

    let item: SvgName;
    switch (itemName) {
      case 'Apple':
        item = getRandomFromArray(['Array_objects/AppleRed', 'Array_objects/AppleGreen'] as const);
        break;
      case 'Counter':
        item = getRandomFromArray([
          'Circles/circle_blue',
          'Circles/circle_red',
          'Circles/circle_yellow',
          'Circles/circle_green'
        ] as const);
        break;
      case 'Cube':
        item = getRandomFromArray([
          'Cubes_blank/Coloured_cube_unlabelled_blue',
          'Cubes_blank/Coloured_cube_unlabelled_purple',
          'Cubes_blank/Coloured_cube_unlabelled_orange',
          'Cubes_blank/Coloured_cube_unlabelled_red',
          'Cubes_blank/Coloured_cube_unlabelled_green',
          'Cubes_blank/Coloured_cube_unlabelled_yellow'
        ] as const);
        break;
      case 'Marble':
        item = 'Marble';
        break;
      case 'Sweet':
        item = getRandomFromArray([
          'Sweets/SweetPink',
          'Sweets/SweetRed',
          'Sweets/SweetGreen',
          'Sweets/SweetBlue'
        ] as const);
        break;
    }

    return { rows, columns, item };
  },
  Component: props => {
    const {
      question: { rows, columns, item },
      translate
    } = props;

    return (
      <QF1ContentAndSentence
        sentence={`<ans/> ${MULT} <ans/> = ${(rows * columns).toLocaleString()}`}
        title={translate.ks1Instructions.completeTheNumberSentence()}
        testCorrect={userAnswer =>
          arraysHaveSameContentsUnordered(userAnswer, [rows.toString(), columns.toString()])
        }
        customMarkSchemeAnswer={{
          answersToDisplay: [rows.toLocaleString(), columns.toLocaleString()],
          answerText: translate.markScheme.factorsInAnyOrder()
        }}
        inputMaxCharacters={1}
        Content={({ dimens }) => (
          <ArrayOfObjects
            customImage={
              <AssetSvg name={item} height={dimens.height / (rows + 1)} width={dimens.width / 7} />
            }
            dimens={{
              height: dimens.height,
              width: dimens.width
            }}
            rowStyle={{ gap: 20 }}
            containerStyle={{ gap: 10 }}
            rows={rows}
            columns={columns}
          />
        )}
        pdfDirection="column"
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'biT',
  description: 'biT',
  keywords: ['Array', 'Multiplication', 'Counters'],
  schema: z
    .object({
      rows: z.number().int().min(2).max(5),
      columns: z.number().int().min(2).max(10),
      color: z.string()
    })
    .refine(val => val.rows !== val.columns, 'rows and columns must be different'),
  simpleGenerator: () => {
    const rows = randomIntegerInclusive(2, 5);
    // need to have two calculations
    const columns = randomIntegerInclusive(2, 10, {
      constraint: x => x !== rows && ((rows === 4 && ![7, 8, 9].includes(x)) || rows !== 4)
    });
    const color = getRandomFromArray(Object.values(ArrayOfObjectsColors)) as string;

    return { rows, columns, color };
  },
  Component: props => {
    const {
      question: { rows, columns, color },
      translate
    } = props;

    return (
      <QF1ContentAndSentences
        sentences={[`<ans/> ${MULT} <ans/>`, `<ans/> ${MULT} <ans/>`]}
        title={translate.ks1Instructions.createTwoDifferentMultiplicationsToMatchArray()}
        pdfTitle={translate.ks1PDFInstructions.writeTwoDifferentMultiplicationsToMatchArray()}
        testCorrect={userAnswer =>
          (arraysHaveSameContents(userAnswer[0], [rows.toString(), columns.toString()]) &&
            arraysHaveSameContents(userAnswer[1], [columns.toString(), rows.toString()])) ||
          (arraysHaveSameContents(userAnswer[1], [rows.toString(), columns.toString()]) &&
            arraysHaveSameContents(userAnswer[0], [columns.toString(), rows.toString()]))
        }
        inputMaxCharacters={1}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [rows.toLocaleString(), columns.toLocaleString()],
            [columns.toLocaleString(), rows.toLocaleString()]
          ]
        }}
        style={{ flexDirection: 'row', justifyContent: 'space-evenly' }}
        pdfSentenceStyle={{ flexDirection: 'row', justifyContent: 'space-evenly' }}
        Content={({ dimens }) => (
          <ArrayOfObjects
            color={color}
            counterSize={Math.min(dimens.width / (columns + 1), dimens.height / (rows + 1))}
            dimens={{
              height: dimens.height,
              width: dimens.width
            }}
            rowStyle={{ gap: 20 }}
            containerStyle={{ gap: 10 }}
            rows={rows}
            columns={columns}
          />
        )}
        pdfDirection="column"
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question3 = newQuestionContent({
  uid: 'biU',
  description: 'biU',
  keywords: ['Array', 'Row', 'Column'],
  schema: z.object({
    row: z.number().int().min(2).max(6),
    column: z.number().int().min(2).max(10)
  }),
  simpleGenerator: () => {
    const row = randomIntegerInclusive(2, 6);
    const column = randomIntegerInclusive(2, 10, {
      constraint: x => (row === 6 && ![7, 8, 9].includes(x)) || row !== 6
    });

    return { row, column };
  },
  Component: props => {
    const {
      question: { row, column },
      translate
    } = props;

    return (
      <QF29CreateArray
        numberOfRows={6}
        numberOfCols={10}
        title={translate.ks1Instructions.tapTheCirclesToMakeArrayThatShowsXMultY(row, column)}
        pdfTitle={translate.ks1PDFInstructions.drawAnArrayToShowXMultY(row, column)}
        testCorrect={[row, column]}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.arrayDimensCanBeFlipped()
        }}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'UseArrays',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1]
});
export default SmallStep;
