import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomBooleanArray,
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  seededRandom
} from '../../../../utils/random';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { View } from 'react-native';
import { AssetSvg } from '../../../../assets/svg';
import { getRandomUniqueKs1Names, ks1NameSchema } from '../../../../utils/names';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';
import { characterNameLabel } from '../../../../utils/characters';
import { getCharacterHeadSvgName } from '../../../../utils/characters';
import { countRange } from '../../../../utils/collections';
import QF21DraggableTenFrames, {
  getTotalCounters
} from '../../../../components/question/questionFormats/QF21DraggableTenFrames';

////
// Questions
////
const Question1 = newQuestionContent({
  uid: 'baE',
  description: 'baE',
  keywords: ['More', 'Fewer'],
  schema: z
    .object({
      numA: z.number().int().min(2).max(8),
      numB: z.number().int().min(2).max(8),
      colour: z.enum(['blue', 'green', 'orange', 'purple', 'red']),
      nameA: ks1NameSchema,
      nameB: ks1NameSchema,
      reversed: z.boolean()
    })
    .refine(
      val => val.nameA !== val.nameB && val.numA !== val.numB,
      'numA and numB must be different and nameA and nameB must be different.'
    ),
  simpleGenerator: () => {
    const [nameA, nameB] = getRandomUniqueKs1Names(2);
    const [numA, numB] = randomUniqueIntegersInclusive(2, 8, 2);
    const reversed = getRandomBoolean();
    const colour = getRandomFromArray(['blue', 'green', 'orange', 'purple', 'red'] as const);
    return { numA, numB, colour, nameA, nameB, reversed };
  },

  Component: ({ question: { numA, numB, colour, nameA, nameB, reversed }, translate }) => {
    let correctInequality: '<' | '>';
    let answerSentence;
    if (reversed) {
      correctInequality = numB < numA ? '<' : '>';
      answerSentence = translate.ks1AnswerSentences.name1HasAnsCubesThanName2({
        name1: nameB,
        name2: nameA
      });
    } else {
      correctInequality = numA < numB ? '<' : '>';
      answerSentence = translate.ks1AnswerSentences.name1HasAnsCubesThanName2({
        name1: nameA,
        name2: nameB
      });
    }

    const cube = `Multi_link_cubes/column_1_${colour}` as const;
    const cubesA = countRange(numA).map(i => (
      <AssetSvg key={`${i}_cubeA`} name={cube} width={75} height={75} />
    ));
    const cubesB = countRange(numB).map(i => (
      <AssetSvg key={`${i}_cubeB`} name={cube} width={75} height={75} />
    ));

    return (
      <QF36ContentAndSentenceDrag<'<' | '>'>
        title={translate.ks1Instructions.dragACardToCompleteTheSentence()}
        pdfTitle={translate.ks1PDFInstructions.useTheWordsToCompleteTheSentence()}
        itemVariant="rectangle"
        items={[
          { value: '>', component: translate.ks1MiscStrings.more() },
          { value: '<', component: translate.ks1MiscStrings.fewer() }
        ]}
        moveOrCopy="move"
        actionPanelVariant="endWide"
        pdfLayout="itemsBottom"
        pdfItemVariant="rectangle"
        Content={({ dimens }) => (
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-evenly',
              alignItems: 'flex-start',
              width: dimens.width
            }}
          >
            <View
              style={{
                flexDirection: 'column',
                justifyContent: 'space-evenly',
                alignItems: 'center',
                flex: 2,
                height: dimens.height
              }}
            >
              <View style={{ rowGap: 15 }}>
                <AssetSvg
                  name={getCharacterHeadSvgName(nameA)}
                  height={dimens.height / 5}
                  width={dimens.width / 5}
                />

                {characterNameLabel(nameA, dimens.width / 5)}
              </View>

              <View style={{ rowGap: 15 }}>
                <AssetSvg
                  name={getCharacterHeadSvgName(nameB)}
                  height={dimens.height / 5}
                  width={dimens.width / 5}
                />

                {characterNameLabel(nameB, dimens.width / 5)}
              </View>
            </View>
            <View
              style={{
                flexDirection: 'column',
                justifyContent: 'space-evenly',
                alignItems: 'center',
                flex: 6,
                height: dimens.height
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  alignContent: 'flex-start',
                  height: dimens.height / 2,
                  width: '100%',
                  paddingLeft: 10
                }}
              >
                {cubesA}
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  alignContent: 'flex-start',
                  height: dimens.height / 2,
                  width: '100%',
                  paddingLeft: 10
                }}
              >
                {cubesB}
              </View>
            </View>
          </View>
        )}
        sentence={answerSentence}
        testCorrect={[correctInequality]}
        questionHeight={1300}
      />
    );
  },
  questionHeight: 1300
});

const Question2 = newQuestionContent({
  uid: 'baF',
  description: 'baF',
  keywords: ['Inequalities'],
  schema: z
    .object({
      item: z.enum(['muffins', 'buttons', 'sweets', 'apples', 'horses']),
      numA: z.number().int().min(1).max(10),
      numB: z.number().int().min(1).max(10),
      arrangementSeed: z.number().int().min(1).max(1000),
      fewerMore: z.enum(['fewer', 'more'])
    })
    .refine(({ numA, numB }) => numA !== numB, 'The numbers cannot be the same'),
  simpleGenerator: () => {
    const item = getRandomFromArray(['muffins', 'buttons', 'sweets', 'apples', 'horses'] as const);
    const arrangementSeed = randomIntegerInclusive(1, 1000);
    const fewerMore = getRandomFromArray(['fewer', 'more'] as const);
    const [numA, numB] = randomUniqueIntegersInclusive(1, 10, 2);
    return { item, numA, numB, fewerMore, arrangementSeed };
  },
  Component: ({ question: { item, numA, numB, fewerMore, arrangementSeed }, translate }) => {
    const itemTranslations = {
      muffins: translate.food.Muffins(2),
      buttons: translate.items.buttons(2),
      sweets: translate.items.sweets(2),
      apples: translate.fruits.Apples(),
      horses: translate.animals.Horses()
    };

    const svgNames = {
      muffins: 'Muffins_individually/Muffin_1',
      buttons: 'Button_blue',
      sweets: 'Base_Ten/Sweets1',
      apples: 'Array_objects/AppleRed',
      horses: 'Horse'
    } as const;

    const random = seededRandom({ arrangementSeed });
    const arrangementA = getRandomBooleanArray(3, 4, numA, random);
    const arrangementB = getRandomBooleanArray(3, 4, numB, random);

    const title =
      fewerMore === 'fewer'
        ? translate.ks1Instructions.selectTheGroupWithFewerObject(itemTranslations[item])
        : translate.ks1Instructions.selectTheGroupWithMoreObject(itemTranslations[item]);

    const pdfTitle =
      fewerMore === 'fewer'
        ? translate.ks1PDFInstructions.tickTheGroupWithFewerObject(itemTranslations[item])
        : translate.ks1PDFInstructions.tickTheGroupWithMoreObject(itemTranslations[item]);

    const correctAnswer = fewerMore === 'fewer' ? Math.min(numA, numB) : Math.max(numA, numB);

    return (
      <QF11SelectImagesUpTo4
        title={title}
        pdfTitle={pdfTitle}
        testCorrect={[correctAnswer]}
        numItems={2}
        pdfShowBorder
        renderItems={dimens => [
          {
            value: numA,
            component: (
              <View style={{ alignItems: 'center', justifyContent: 'center' }}>
                {arrangementA.map((row, rowIndex) => (
                  <View key={`row-${rowIndex}`} style={{ flexDirection: 'row' }}>
                    {row.map((cell, colIndex) => (
                      <View
                        key={`cell-${rowIndex}-${colIndex}`}
                        style={{
                          width: dimens.dimens.width / 4,
                          height: dimens.dimens.height / 3,
                          padding: 8
                        }}
                      >
                        {cell ? (
                          <AssetSvg
                            name={svgNames[item]}
                            height={dimens.dimens.height / 3 - 16}
                            width={dimens.dimens.width / 4 - 16}
                          />
                        ) : (
                          <></>
                        )}
                      </View>
                    ))}
                  </View>
                ))}
              </View>
            )
          },
          {
            value: numB,
            component: (
              <View style={{ alignItems: 'center', justifyContent: 'center' }}>
                {arrangementB.map((row, rowIndex) => (
                  <View key={`row-${rowIndex}`} style={{ flexDirection: 'row' }}>
                    {row.map((cell, colIndex) => (
                      <View
                        key={`cell-${rowIndex}-${colIndex}`}
                        style={{
                          width: dimens.dimens.width / 4,
                          height: dimens.dimens.height / 3,
                          padding: 8
                        }}
                      >
                        {cell ? (
                          <AssetSvg
                            name={svgNames[item]}
                            height={dimens.dimens.height / 3 - 16}
                            width={dimens.dimens.width / 4 - 16}
                          />
                        ) : (
                          <></>
                        )}
                      </View>
                    ))}
                  </View>
                ))}
              </View>
            )
          }
        ]}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'baG',
  description: 'baG',
  keywords: ['Inequalities', 'Counters'],
  schema: z
    .object({
      nameA: ks1NameSchema,
      nameB: ks1NameSchema,
      numA: z.number().int().min(2).max(9),
      fewerMore: z.enum(['fewer', 'more'])
    })
    .refine(val => val.nameA !== val.nameB, 'nameA and nameB must be different.'),
  simpleGenerator: () => {
    const [nameA, nameB] = getRandomUniqueKs1Names(2);
    const numA = randomIntegerInclusive(2, 9);
    const fewerMore = getRandomFromArray(['fewer', 'more'] as const);
    return { nameA, nameB, numA, fewerMore };
  },

  Component: ({ question: { nameA, nameB, numA, fewerMore }, translate }) => {
    const title =
      fewerMore === 'fewer'
        ? translate.ks1Instructions.nameAHasNCountersNameBHasFewerCountersThanNameADragTheCounters({
            nameA,
            nameB,
            numA
          })
        : translate.ks1Instructions.nameAHasNCountersNameBHasMoreCountersThanNameADragTheCounters({
            nameA,
            nameB,
            numA
          });

    const pdfTitle =
      fewerMore === 'fewer'
        ? translate.ks1PDFInstructions.nameAHasNCountersNameBHasFewerCountersThanNameADrawCounters({
            nameA,
            nameB,
            numA
          })
        : translate.ks1PDFInstructions.nameAHasNCountersNameBHasMoreCountersThanNameADrawCounters({
            nameA,
            nameB,
            numA
          });

    // TODO - It would be nice if in the fewer case here, the quesiton was asking to remove the  counters from a ten frame
    return (
      <QF21DraggableTenFrames
        title={title}
        pdfTitle={pdfTitle}
        numberOfTenFrames={1}
        tenFrameFlexDirection="row"
        items={['red']}
        exampleCorrectAnswer={(tenFrameIndex, index) => {
          if (fewerMore === 'fewer' && tenFrameIndex === 0 && index - 1 < numA) return 'red';
          if (fewerMore === 'more' && tenFrameIndex === 0 && index <= numA) return 'red';
        }}
        testCorrect={userAnswer => {
          return fewerMore === 'fewer'
            ? getTotalCounters(userAnswer) < numA
            : getTotalCounters(userAnswer) > numA;
        }}
        customMarkSchemeAnswerText={
          fewerMore === 'fewer'
            ? translate.ks1AnswerSentences.anyArrangementOfFewerThanNumCounters(numA)
            : translate.ks1AnswerSentences.anyArrangementOfMoreThanNumCounters(numA)
        }
      />
    );
  },
  questionHeight: 1300
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'FewerMoreSame',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1]
});
export default SmallStep;
