import z from 'zod';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  shuffle
} from '../../../../utils/random';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { ArrayOfObjectsColors } from '../../../../theme/colors';
import { ArrayOfObjects } from '../../../../components/question/representations/ArrayOfObjects';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { DIV } from '../../../../constants';
import { getRandomKs1Name, ks1NameSchema } from '../../../../utils/names';
import { numberEnum } from '../../../../utils/zod';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import { isEqual } from '../../../../utils/matchers';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'biV',
  description: 'biV',
  keywords: ['Array', 'Grouping', 'Division'],
  schema: z
    .object({
      rows: z.number().int().min(2).max(5),
      columns: z.number().int().min(2).max(10),
      color: z.string()
    })
    .refine(
      val => [val.columns, val.rows].some(val => [2, 3, 4, 5, 10].includes(val)),
      '[columns, rows] must include either 2,3,4,5 or 10'
    ),
  simpleGenerator: () => {
    const number1 = getRandomFromArray([2, 3, 4, 5, 10] as const);
    const min = number1 === 10 ? 5 : number1 === 4 ? 6 : 10;
    const number2 = randomIntegerInclusive(2, min);
    const color = getRandomFromArray(Object.values(ArrayOfObjectsColors)) as string;

    const [rows, columns] =
      number1 === 10
        ? [number2, number1]
        : number2 > 5
        ? [number1, number2]
        : shuffle([number1, number2]);

    return { rows, columns, color };
  },
  Component: props => {
    const {
      question: { rows, columns, color },
      translate
    } = props;

    const total = rows * columns;
    return (
      <QF1ContentAndSentences
        sentences={[
          translate.ks1AnswerSentences.thereAreAnsCountersAltogether(),
          translate.answerSentences.thereAreAnsEqualGroupsOfAns()
        ]}
        title={translate.ks1Instructions.completeTheSentences()}
        testCorrect={userAnswer =>
          userAnswer[0][0] === total.toString() &&
          isEqual(total.toString())(
            (Number(userAnswer[1][0]) * Number(userAnswer[1][1])).toString()
          )
        }
        inputMaxCharacters={2}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [(rows * columns).toLocaleString()],
            [columns.toLocaleString(), rows.toLocaleString()]
          ],
          answerText: translate.markScheme.acceptAnyTwoFactors()
        }}
        Content={({ dimens }) => (
          <ArrayOfObjects
            color={color}
            counterSize={Math.min(dimens.width / (columns + 1), dimens.height / (rows + 1))}
            dimens={{
              height: dimens.height,
              width: dimens.width
            }}
            rowStyle={{ gap: 20 }}
            containerStyle={{ gap: 10 }}
            rows={rows}
            columns={columns}
          />
        )}
        pdfDirection="column"
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'biW',
  description: 'biW',
  keywords: ['Array', 'Grouping', 'Division'],
  schema: z
    .object({
      rows: z.number().int().min(2).max(5),
      columns: z.number().int().min(2).max(10),
      color: z.string()
    })
    .refine(
      val => [val.columns, val.rows].some(val => [2, 3, 4, 5, 10].includes(val)),
      '[columns, rows] must include either 2,3,4,5 or 10'
    ),
  simpleGenerator: () => {
    const number1 = getRandomFromArray([2, 3, 4, 5, 10] as const);
    const min = number1 === 10 ? 5 : number1 === 4 ? 6 : 10;
    const number2 = randomIntegerInclusive(2, min);
    const color = getRandomFromArray(Object.values(ArrayOfObjectsColors)) as string;

    const [rows, columns] =
      number1 === 10
        ? [number2, number1]
        : number2 > 5
        ? [number1, number2]
        : shuffle([number1, number2]);

    return { rows, columns, color };
  },
  Component: props => {
    const {
      question: { rows, columns, color },
      translate
    } = props;
    const total = rows * columns;
    return (
      <QF1ContentAndSentence
        sentence={`${total.toLocaleString()} ${DIV} <ans/> = <ans/>`}
        title={translate.ks1Instructions.completeTheDivisionToMatchArray()}
        testCorrect={([userAnswer1, userAnswer2]) =>
          isEqual(total.toString())((Number(userAnswer1) * Number(userAnswer2)).toString())
        }
        inputMaxCharacters={2}
        customMarkSchemeAnswer={{
          answersToDisplay: [columns.toLocaleString(), rows.toLocaleString()],
          answerText: translate.markScheme.acceptAnyTwoFactors()
        }}
        Content={({ dimens }) => (
          <ArrayOfObjects
            color={color}
            counterSize={Math.min(dimens.width / (columns + 1), dimens.height / (rows + 1))}
            dimens={{
              height: dimens.height,
              width: dimens.width
            }}
            rowStyle={{ gap: 20 }}
            containerStyle={{ gap: 10 }}
            rows={rows}
            columns={columns}
          />
        )}
        pdfDirection="column"
        questionHeight={850}
      />
    );
  },
  questionHeight: 850
});

const Question3 = newQuestionContent({
  uid: 'biX',
  description: 'biX',
  keywords: ['Division', 'Grouping'],
  schema: z
    .object({
      name: ks1NameSchema,
      groupSizes: numberEnum([2, 3, 4, 5, 10]),
      total: z.number().int().min(4).max(25),
      item: z.enum(['Apple', 'Pear', 'Sweet', 'Muffin', 'Cookie', 'Pencil'])
    })
    .refine(val => val.total % val.groupSizes === 0, 'total is a multiple of group sizes'),
  simpleGenerator: () => {
    const name = getRandomKs1Name();
    const groupSizes = getRandomFromArray([2, 3, 4, 5, 10] as const);
    const total = randomIntegerInclusiveStep(2 * groupSizes, 25, groupSizes);

    const item = getRandomFromArray([
      'Apple',
      'Pear',
      'Sweet',
      'Muffin',
      'Cookie',
      'Pencil'
    ] as const);

    return {
      name,
      groupSizes,
      total,
      item
    };
  },
  Component: ({ question, translate }) => {
    const { name, groupSizes, total, item } = question;

    const translations = {
      Apple: translate.fruits.Apples(),
      Pear: translate.fruits.Pears(),
      Sweet: translate.enums.Base10RepresentationVariant.Sweets(),
      Muffin: translate.food.Muffins(2),
      Cookie: translate.food.Cookies(2),
      Pencil: translate.objects.Pencils()
    };

    const title = ['Apple', 'Pear', 'Sweet'].includes(item)
      ? translate.ks1Instructions.objectComeInBagsOfYNameHasObjectsHowMany({
          name,
          object: translations[item],
          perContainer: groupSizes,
          total
        })
      : ['Cookie', 'Muffin'].includes(item)
      ? translate.ks1Instructions.objectComeOnPlatesOfYNameHasObjectsHowMany({
          name,
          object: translations[item],
          perContainer: groupSizes,
          total
        })
      : translate.ks1Instructions.objectComeInPotsOfYNameHasObjectsHowMany({
          name,
          object: translations[item],
          perContainer: groupSizes,
          total
        });

    return (
      <QF2AnswerBoxOneSentence
        title={title}
        testCorrect={[(total / groupSizes).toString()]}
        sentence={'<ans/>'}
        mainPanelContainerStyle={{ justifyContent: 'flex-end', alignItems: 'flex-end' }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MakeEqualGroupsGrouping',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question2]
});
export default SmallStep;
