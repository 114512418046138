import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import { newQuestionContent } from '../../../Question';
import {
  getRandomUniqueScaleObjectNames,
  scaleObjectAsArticleWord,
  ScaleObjectName,
  scaleObjects,
  scaleObjectsSchema
} from '../../../../utils/objects';
import { getRandomBoolean, getRandomFromArray, rejectionSample } from '../../../../utils/random';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import WoodenBalanceScale from '../../../../components/question/representations/WoodenBalanceScale';
import { AssetSvg } from '../../../../assets/svg';
import { View } from 'react-native';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';
import { numberEnum } from '../../../../utils/zod';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bjH',
  description: 'bjH',
  keywords: [
    'Greater than',
    'Less than',
    'The same as',
    'Heavier',
    'Lighter',
    'Balance scales',
    'Mass'
  ],
  schema: z.object({
    scaleObjectNames: z.array(scaleObjectsSchema).length(2),
    scaleDirection: z.enum(['left', 'right', 'equal']),
    variation: z.boolean()
  }),
  questionHeight: 1200,
  simpleGenerator: () => {
    const scaleObjectNames = rejectionSample(
      () => getRandomUniqueScaleObjectNames(2),
      val => val.filter(name => name.toLowerCase().includes('cube')).length === 0
    );

    const scaleDirection = getRandomFromArray(['left', 'right', 'equal'] as const);

    const variation = getRandomBoolean();

    return { scaleObjectNames, scaleDirection, variation };
  },
  Component: props => {
    const {
      question: { scaleObjectNames, scaleDirection, variation },
      translate
    } = props;

    const [scaleObjectNameA, scaleObjectNameB] = scaleObjectNames;
    const translatedScaleObjectNameA = scaleObjectAsArticleWord(scaleObjectNameA, translate);
    const translatedScaleObjectNameB = scaleObjectAsArticleWord(scaleObjectNameB, translate);

    const more = variation
      ? translate.operations.greaterThan()
      : translate.operations.heavierThan();
    const less = variation ? translate.operations.lessThan() : translate.operations.lighterThan();
    const same = variation ? translate.operations.theSameAs() : translate.misc.theSameMassAs();

    const correctAnswer =
      scaleDirection === 'left' ? more : scaleDirection === 'right' ? less : same;

    return (
      <QF36ContentAndSentenceDrag
        title={translate.ks1Instructions.dragACardToCompleteTheSentence()}
        pdfTitle={translate.instructions.useCardsCompleteSentence()}
        sentence={
          variation
            ? translate.ks1AnswerSentences.theMassOfXIsAnsMassOfY(
                translatedScaleObjectNameA,
                translatedScaleObjectNameB
              )
            : translate.ks1AnswerSentences.xIsAnsY(
                translatedScaleObjectNameA,
                translatedScaleObjectNameB
              )
        }
        Content={({ dimens }) => (
          <WoodenBalanceScale
            items={[scaleObjectNameA, scaleObjectNameB]}
            dimens={dimens}
            containerStyle={{ marginTop: 50 }}
            direction={scaleDirection}
          />
        )}
        itemVariant="rectangle"
        pdfItemVariant="rectangle"
        pdfLayout="itemsTop"
        actionPanelVariant="endWide"
        sentencesStyle={{ alignItems: 'flex-start' }}
        questionHeight={1200}
        items={[more, same, less]}
        testCorrect={[correctAnswer]}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bjI',
  description: 'bjI',
  keywords: ['Heavier', 'Lighter', 'Balance scales', 'Mass'],
  schema: z.object({
    scaleObjectNames: z.array(scaleObjectsSchema).length(2),
    lighterOrHeavier: z.enum(['lighter', 'heavier']),
    scaleDirection: z.enum(['left', 'right'])
  }),
  simpleGenerator: () => {
    const lighterOrHeavier = getRandomFromArray(['lighter', 'heavier'] as const);
    const scaleDirection = getRandomFromArray(['left', 'right'] as const);

    const scaleObjectNames = rejectionSample(
      () => getRandomUniqueScaleObjectNames(2),
      val =>
        val.filter(
          name => name.toLowerCase().includes('cube') || name.toLowerCase().includes('muffin')
        ).length === 0
    );

    return { scaleObjectNames, lighterOrHeavier, scaleDirection };
  },
  Component: props => {
    const {
      question: { scaleObjectNames, lighterOrHeavier, scaleDirection },
      translate
    } = props;

    const titleWeight =
      lighterOrHeavier === 'lighter' ? translate.keywords.Lighter() : translate.keywords.Heavier();

    const [scaleObjectNameA, scaleObjectNameB] = scaleObjectNames;

    const [heavierItemName, lighterItemName] = [scaleObjectNameA, scaleObjectNameB].sort(
      (a, b) => scaleObjects[b].weight - scaleObjects[a].weight
    );

    // Items order matches the scaleDirection
    const items: [ScaleObjectName, ScaleObjectName] =
      scaleDirection === 'left'
        ? [heavierItemName, lighterItemName]
        : [lighterItemName, heavierItemName];

    // Correct answer depends on the weight
    const correctAnswer = lighterOrHeavier === 'lighter' ? lighterItemName : heavierItemName;

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.ks1Instructions.selectTheXWeightObject(titleWeight)}
        pdfTitle={translate.ks1PDFInstructions.tickTheXWeightObject(titleWeight)}
        testCorrect={[correctAnswer]}
        numItems={2}
        Content={({ dimens }) => (
          <WoodenBalanceScale
            items={items}
            dimens={dimens}
            containerStyle={{ marginTop: 50 }}
            direction={scaleDirection}
          />
        )}
        renderItems={({ dimens }) => {
          return items.map(item => ({
            value: item,
            component: (
              <AssetSvg
                name={scaleObjects[item].name}
                height={dimens.height * 0.3}
                width={dimens.width * 0.3}
              />
            )
          }));
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bjJ',
  description: 'bjJ',
  keywords: ['Mass', 'Measure', 'Cubes', 'Balance scales', 'Compare'],
  schema: z
    .object({
      scaleObjectNames: z.array(scaleObjectsSchema).length(2),
      lighterOrHeavier: z.enum(['lighter', 'heavier']),
      scaleCubes: numberEnum([1, 2]),
      cube: scaleObjectsSchema
    })
    .refine(
      ({ scaleObjectNames, scaleCubes }) =>
        scaleObjectNames.map(obj => scaleObjects[obj].weight * scaleCubes <= 10),
      'objects should be a max of 10 cubes'
    ),
  simpleGenerator: () => {
    const lighterOrHeavier = getRandomFromArray(['lighter', 'heavier'] as const);

    const scaleObjectNames = rejectionSample(
      () => getRandomUniqueScaleObjectNames(2),
      val =>
        val.filter(
          name => name.toLowerCase().includes('cube') || name.toLowerCase().includes('muffin')
        ).length === 0
    );

    const cube = rejectionSample(
      () => getRandomUniqueScaleObjectNames(1),
      val => val.filter(name => name.toLowerCase().includes('cube')).length === 1
    )[0];

    const scaleCubes = getRandomFromArray([1, 2] as const);

    return { scaleObjectNames, lighterOrHeavier, scaleCubes, cube };
  },
  Component: props => {
    const {
      question: { scaleObjectNames, lighterOrHeavier, scaleCubes, cube },
      translate
    } = props;

    const title =
      lighterOrHeavier === 'lighter' ? 'selectTheLighterObject' : 'selectTheHeavierObject';
    const pdfTitle =
      lighterOrHeavier === 'lighter' ? 'tickTheLighterObject' : 'tickTheHeavierObject';

    const [scaleObjectNameA, scaleObjectNameB] = scaleObjectNames;

    const [heavierItemName, lighterItemName] = [scaleObjectNameA, scaleObjectNameB].sort(
      (a, b) => scaleObjects[b].weight - scaleObjects[a].weight
    );

    // Correct answer depends on the weight
    const correctAnswer = lighterOrHeavier === 'lighter' ? lighterItemName : heavierItemName;

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.ks1Instructions[title]()}
        pdfTitle={translate.ks1PDFInstructions[pdfTitle]()}
        testCorrect={[correctAnswer]}
        numItems={2}
        Content={({ dimens }) => (
          <View
            style={{
              ...dimens,
              flexDirection: 'row',
              justifyContent: 'space-evenly',
              alignItems: 'center'
            }}
          >
            <WoodenBalanceScale
              amount={[1, scaleObjects[scaleObjectNameA].weight * scaleCubes]}
              items={[scaleObjectNameA, cube]}
              dimens={{ height: dimens.height, width: dimens.width * 0.55 }}
              containerStyle={{ marginTop: 50 }}
              stackInfront
              iconWidth={40}
              scaleSingleObject={[false, true]}
            />
            <WoodenBalanceScale
              amount={[1, scaleObjects[scaleObjectNameB].weight * scaleCubes]}
              items={[scaleObjectNameB, cube]}
              dimens={{ height: dimens.height, width: dimens.width * 0.55 }}
              containerStyle={{ marginTop: 50 }}
              stackInfront
              iconWidth={40}
              scaleSingleObject={[false, true]}
            />
          </View>
        )}
        renderItems={({ dimens }) => {
          return scaleObjectNames.map(item => ({
            value: item,
            component: (
              <AssetSvg
                name={scaleObjects[item].name}
                height={dimens.height * 0.3}
                width={dimens.width * 0.3}
              />
            )
          }));
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CompareMass',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
