import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import WoodenBalanceScale from '../../../../components/question/representations/WoodenBalanceScale';
import { z } from 'zod';
import { ScaleObjectName, scaleObjectNames, scaleObjectsSchema } from '../../../../utils/objects';
import {
  getRandomFromArray,
  getRandomFromArrayWithWeights,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import { View } from 'react-native';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bdB',
  description: 'bdB',
  keywords: ['Measure', 'Mass', 'Cubes', 'Balance scales'],
  schema: z.object({
    randomScaleObjectWithoutCube: scaleObjectsSchema,
    cubesAmount: z.number().int().min(2).max(10)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const randomScaleObjectWithoutCube = getRandomSubArrayFromArray(
      scaleObjectNames.filter(name => !name.toLowerCase().includes('cube')),
      1
    )[0];

    const cubesAmount = randomIntegerInclusive(2, 10);

    return { randomScaleObjectWithoutCube, cubesAmount };
  },
  Component: props => {
    const {
      question: { randomScaleObjectWithoutCube, cubesAmount },
      translate
    } = props;

    const randomCubeObject = getRandomSubArrayFromArray(
      scaleObjectNames.filter(name => name.toLowerCase().includes('cube')),
      1
    )[0];

    const uniqueScaleObjectNames = [randomScaleObjectWithoutCube, randomCubeObject];

    return (
      <QF1ContentAndSentence
        sentence={translate.ks1AnswerSentences.theMassOfTheXMassObjectIsAnsCubes(
          uniqueScaleObjectNames[0]
        )}
        title={translate.ks1Instructions.completeTheSentence()}
        testCorrect={[cubesAmount.toString()]}
        Content={({ dimens }) => (
          <WoodenBalanceScale
            amount={[1, cubesAmount]}
            items={[uniqueScaleObjectNames[0], uniqueScaleObjectNames[1]]}
            containerStyle={{ marginTop: 80 }}
            dimens={dimens}
            stackInfront
          />
        )}
        pdfDirection="column"
        questionHeight={1000}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bdC',
  description: 'bdC',
  keywords: ['Measure', 'Mass', 'Cubes', 'Balance scales'],
  schema: z
    .object({
      scaleObjectName: scaleObjectsSchema,
      cubesAmount: z.number().int().min(1).max(10),
      correctForm: z.enum(['equal', 'left', 'right']),
      incorrectForm: z.enum(['equal', 'left', 'right', '1 more', '1 less'])
    })
    .refine(
      val => val.correctForm !== val.incorrectForm,
      'correctForm must not be the same as incorrectForm'
    ),
  simpleGenerator: () => {
    const options = scaleObjectNames.filter(name => !name.toLowerCase().includes('cube'));

    const scaleObjectName = getRandomFromArray(options) as ScaleObjectName;

    const cubesAmount = randomIntegerInclusive(1, 10);

    const correctForm = getRandomFromArrayWithWeights(
      ['equal', 'left', 'right'] as const,
      [3, 1, 1]
    );

    const incorrectOptions = [
      'equal',
      'left',
      'right',
      cubesAmount !== 10 ? '1 more' : null,
      cubesAmount !== 1 ? '1 less' : null
    ].filter(item => item !== correctForm && item !== null);
    const incorrectForm = getRandomFromArray(
      incorrectOptions as ['equal', 'left', 'right', '1 more', '1 less']
    );

    return { scaleObjectName, cubesAmount, correctForm, incorrectForm };
  },
  questionHeight: 1000,
  Component: props => {
    const {
      question: { scaleObjectName, cubesAmount, correctForm, incorrectForm },
      translate,
      displayMode
    } = props;

    const randomCubeObject = getRandomSubArrayFromArray(
      scaleObjectNames.filter(name => name.toLowerCase().includes('cube')),
      1
    )[0];

    const uniqueScaleObjectNames = [scaleObjectName, randomCubeObject];

    const incorrectCubesAmount =
      incorrectForm === '1 less'
        ? cubesAmount - 1
        : incorrectForm === '1 more'
        ? cubesAmount + 1
        : cubesAmount;

    const correctFormTitle =
      correctForm === 'equal'
        ? translate.operations.equalTo()
        : correctForm === 'left'
        ? translate.operations.moreThan()
        : translate.operations.lessThan();

    const customPositionValuesLeft =
      displayMode === 'digital' ? ([25, 121] as [number, number]) : ([25, 155] as [number, number]);
    const customPositionValuesRight =
      displayMode === 'digital' ? ([137, 40] as [number, number]) : ([180, 60] as [number, number]);

    return (
      <QF11SelectImagesUpTo4
        title={translate.ks1Instructions.theMassOfTheXObjectIsYAmountThanZCubesSelectThePictureThatShowsThis(
          scaleObjectName,
          correctFormTitle,
          cubesAmount
        )}
        pdfTitle={translate.ks1PDFInstructions.theMassOfTheXObjectIsYAmountThanZCubesTickThePictureThatShowsThis(
          scaleObjectName,
          correctFormTitle,
          cubesAmount
        )}
        testCorrect={[1]}
        numItems={2}
        renderItems={({ dimens }) => {
          return shuffle(
            [
              {
                value: 1,
                component: (
                  <WoodenBalanceScale
                    items={[uniqueScaleObjectNames[0], uniqueScaleObjectNames[1]]}
                    amount={[1, cubesAmount]}
                    dimens={dimens}
                    containerStyle={{ marginTop: 90 }}
                    direction={correctForm}
                    iconWidth={50}
                    widthScaling
                    stackInfront
                    customPositionValues={
                      correctForm === 'left'
                        ? customPositionValuesLeft
                        : correctForm === 'right'
                        ? customPositionValuesRight
                        : undefined
                    }
                  />
                )
              },
              {
                value: 2,
                component: (
                  <WoodenBalanceScale
                    items={[uniqueScaleObjectNames[0], uniqueScaleObjectNames[1]]}
                    amount={[1, incorrectCubesAmount]}
                    dimens={dimens}
                    containerStyle={{ marginTop: 90 }}
                    direction={
                      incorrectForm === '1 less' || incorrectForm === '1 more'
                        ? correctForm
                        : incorrectForm
                    }
                    iconWidth={50}
                    widthScaling
                    stackInfront
                    customPositionValues={
                      incorrectForm === '1 less' || incorrectForm === '1 more'
                        ? correctForm === 'left'
                          ? customPositionValuesLeft
                          : correctForm === 'right'
                          ? customPositionValuesRight
                          : undefined
                        : incorrectForm === 'left'
                        ? customPositionValuesLeft
                        : incorrectForm === 'right'
                        ? customPositionValuesRight
                        : undefined
                    }
                  />
                )
              }
            ],
            { random: seededRandom(props.question) }
          );
        }}
        questionHeight={1000}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bdD',
  description: 'bdD',
  keywords: ['Measure', 'Mass', 'Cubes'],
  schema: z
    .object({
      cubesHeavy: z.number().int().min(2).max(6),
      cubesLight: z.number().int().min(5).max(10),
      cubeColour: z.enum(['blue', 'green', 'orange', 'purple', 'red'])
    })
    .refine(val => val.cubesLight > val.cubesHeavy, 'cubesLight must be greater than cubesHeavy'),
  simpleGenerator: () => {
    const { cubesHeavy, cubesLight, cubeColour } = rejectionSample(
      () => {
        const cubesDifference = randomIntegerInclusive(3, 5);
        const cubesHeavy = randomIntegerInclusive(2, 6);
        const cubesLight = randomIntegerInclusive(5, Math.min(cubesHeavy + cubesDifference, 10));
        const cubeColour = getRandomFromArray([
          'blue',
          'green',
          'orange',
          'purple',
          'red'
        ] as const);

        return { cubesHeavy, cubesLight, cubeColour };
      },
      ({ cubesHeavy, cubesLight }) => cubesLight - cubesHeavy >= 3 && cubesLight - cubesHeavy <= 5
    );

    return { cubesHeavy, cubesLight, cubeColour };
  },
  Component: props => {
    const {
      question: { cubesHeavy, cubesLight, cubeColour },
      translate
    } = props;

    // Add all possible correct answers
    const correctAnswers: number[] = [];

    for (let i = cubesHeavy + 1; i < cubesLight; i++) {
      correctAnswers.push(i);
    }

    // Create an array for all possible answers in the range
    const allPossibleAnswers = [];
    for (let i = 1; i <= cubesLight; i++) {
      allPossibleAnswers.push(i);
    }

    // Find incorrect answers by filtering out correct ones
    const incorrectAnswers = getRandomSubArrayFromArray(
      allPossibleAnswers.filter(answer => !correctAnswers.includes(answer)),
      4 - correctAnswers.length
    );

    // Split cubes SVGs into columns to prevent them overflowing selectable
    function splitCubes(number: number) {
      const svgFiles = [];

      // While the number is greater than 4, keep subtracting 4 and adding 4 cubes
      while (number > 4) {
        svgFiles.push(`Multi_link_cubes/column_4_${cubeColour}`);
        number -= 4;
      }

      if (number === 1) {
        // If the remainder is exactly 1, use a single cube
        svgFiles.push(`Multi_link_cubes/column_1_${cubeColour}`);
      } else if (number > 0) {
        // For other cases, push remainder of cubes
        svgFiles.push(`Multi_link_cubes/column_${number}_${cubeColour}`);
      }

      return svgFiles;
    }

    // Combine correct/incorrect answers
    const cubes = [
      ...correctAnswers.map(ans => splitCubes(ans)),
      ...incorrectAnswers.map(ans => splitCubes(ans))
    ];

    // Loop over cubes and return cubes
    const items = shuffle(
      cubes.map((val, idx) => {
        return {
          value: idx + 1,
          component: (
            <View style={{ display: 'flex', flexDirection: 'row', columnGap: 6 }}>
              {val.map((cubeIcon, idx) => {
                return <AssetSvg key={idx} name={cubeIcon as SvgName} width={50} />;
              })}
            </View>
          )
        };
      }),
      { random: seededRandom(props.question) }
    );

    return (
      <QF11SelectImagesUpTo4
        title={translate.ks1Instructions.theToyCarIsHeavierThenXCubesButLighterThanYCubesSelectAllTheMassesThatTheCarCouldBe(
          cubesHeavy,
          cubesLight
        )}
        pdfTitle={translate.ks1PDFInstructions.theToyCarIsHeavierThenXCubesButLighterThanYCubesTickAllTheMassesThatTheCarCouldBe(
          cubesHeavy,
          cubesLight
        )}
        testCorrect={correctAnswers.map((_val, idx) => idx + 1)}
        numItems={4}
        multiSelect
        renderItems={items}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MeasureMass',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question3]
});
export default SmallStep;
