import { AssetSvg } from 'common/src/assets/svg';
import { integerToWord } from './math';
import { sortNumberArray } from './collections';
import { getRandomFromArray } from './random';
import PngImage from 'common/src/utils/pngImage';
import { TranslationFunctions } from '../i18n/i18n-types';

/**
 * Function that takes in a number and converts to a currency string. Defaults to GBP.
 * Optional props allow the user to change the currency, locale, whether the currency symbol is narrow or not,
 * whether to include the currency symbol or not, and whether to include the decimal numbers or not.
 */
export function numToCurrency({
  amount,
  withoutSymbol,
  currency,
  locale,
  noDecimals
}: {
  amount: number;
  withoutSymbol?: boolean;
  currency?: string;
  locale?: string;
  noDecimals?: boolean;
}) {
  const amountString = new Intl.NumberFormat(locale ?? 'en-GB', {
    style: 'currency',
    currency: currency ?? 'GBP',
    currencyDisplay: 'narrowSymbol'
  }).format(amount);

  // TODO: This assumes the currency's decimals are separated from the integer with a '.' symbol,
  // but not all currencies / locales follow this pattern, e.g. €12.345,36 for Euros.
  const amountFormatted = noDecimals ? amountString.split('.')[0] : amountString;

  // TODO: This assumes the first character is a currency unit. Not all currencies follow this pattern
  // e.g. 12€ can be used for Euros.
  return withoutSymbol ? amountFormatted.substring(1) : amountFormatted;
}

export const possibleMoneyDenominations = [
  5000, 2000, 1000, 500, 200, 100, 50, 20, 10, 5, 2, 1
] as const;

export type PossibleMoneyDenominations = (typeof possibleMoneyDenominations)[number];

export const moneyPenceToString = {
  5000: '£50',
  2000: '£20',
  1000: '£10',
  500: '£5',
  200: '£2',
  100: '£1',
  50: '50p',
  20: '20p',
  10: '10p',
  5: '5p',
  2: '2p',
  1: '1p'
};

export const moneyStringToPence: Record<
  '£50' | '£20' | '£10' | '£5' | '£2' | '£1' | '50p' | '20p' | '10p' | '5p' | '2p' | '1p',
  number
> = {
  '£50': 5000,
  '£20': 2000,
  '£10': 1000,
  '£5': 500,
  '£2': 200,
  '£1': 100,
  '50p': 50,
  '20p': 20,
  '10p': 10,
  '5p': 5,
  '2p': 2,
  '1p': 1
};

/**
 * Function that takes in a total amount and a currency type ('pounds' or 'pence')
 * and returns an array of the highest denominations of the specified currency needed to make this amount.
 * For pounds, it returns the highest denominations of pounds possible,
 * e.g., moneyToHighestDenominations(15, 'pounds') would return ['£10', '£5'] rather than ['£5', '£5', '£5'].
 * Similarly for pence, it returns the highest denominations of pence possible,
 * e.g., moneyToHighestDenominations(3, 'pence') would return ['2p', '1p'] rather than ['1p', '1p', '1p'].
 * customDenominations can be passed as the third parameter to provide specific coin denominations.
 * For e.g moneyToHighestDenominations(5, 'pounds', 1) would return ['£1', '£1', '£1', '£1', '£1']
 */
export function moneyToHighestDenominations(
  total: number,
  poundsOrPence: 'pounds' | 'pence',
  customDenominations?: PossibleMoneyDenominations[]
): string[] {
  // Return custom denominations if passed in
  // Else return all default denominations
  const denominations = customDenominations
    ? sortNumberArray(customDenominations, 'descending')
    : [50, 20, 10, 5, 2, 1];
  const result: string[] = [];

  for (const denomination of denominations) {
    while (total >= denomination) {
      result.push(
        poundsOrPence === 'pence'
          ? denomination > 99
            ? `£${denomination / 100}`
            : `${denomination}p`
          : `£${denomination}`
      );
      total -= denomination;
    }
  }
  // If total doesn't equal 0 throw error
  // Custom denominations provided don't have a combination to sum upto total
  if (total !== 0)
    throw new Error(`Custom denominations provided [${customDenominations}] don't sum upto total.`);
  return result;
}

/**
 * Function to return a specified number of denominations.
 * For example if you want 3 denominations it may return [50p, 20p, 1p]
 * or it can equally return [1p, 1p, 1p]. You can specify whether it is
 * to generate pounds or pence and will also accept a custom array
 * of possible denominations incase you need more control.
 * This function does not prevent exchanges, so if you do not want
 * values that add up to more than 100p then you will need to control
 * that via a constraint.
 */
export function moneyFromXDenominations(
  numberOfDenominations: number,
  poundsOrPence: 'pounds' | 'pence',
  customDenominations?: PossibleMoneyDenominations[]
): {
  sum: number;
  denominations: string[];
  valuesInP: number[];
} {
  const usableDenominations = customDenominations ? customDenominations : [50, 20, 10, 5, 2, 1];
  let sum = 0;
  const denominationsRaw: number[] = [];

  for (let i = 0; i < numberOfDenominations; i++) {
    const denomination = getRandomFromArray(usableDenominations) ?? 0;
    sum += denomination;
    denominationsRaw.push(denomination);
  }
  const denominationsValues = sortNumberArray(denominationsRaw, 'descending');

  const denominations = denominationsValues.map(denomination =>
    poundsOrPence === 'pence' ? `${denomination}p` : `£${denomination}`
  );

  const valuesInP = denominationsValues.map(denomination =>
    poundsOrPence === 'pence' ? denomination : denomination * 100
  );
  return { sum, denominations, valuesInP };
}

/**
 * Function that takes in a total amount of pence and returns an array of the pound notes and coins needed to make this amount.
 * This function calls into poundsToNotesAndCoins and penceToCoins to return all these.
 * This should be useful for larger amounts of money - ensure that money is passed in pence, not pounds.
 */
export function totalPenceToPoundsAndPence(totalPence: number): string[] {
  const totalPounds = Math.floor(totalPence / 100);
  const remainingPence = totalPence % 100;

  const money = [];

  if (totalPounds > 0) {
    money.push(...moneyToHighestDenominations(totalPounds, 'pounds'));
  }

  if (remainingPence > 0) {
    money.push(...moneyToHighestDenominations(remainingPence, 'pence'));
  }

  return money;
}

/**
 * Function that takes in a total amount of pence and returns an object with properties of pounds and pence
 */
export function penceToPoundsAndPence(pence: number) {
  const pounds = Math.floor(pence / 100);
  const remainingPence = pence % 100;

  return {
    pounds: pounds,
    pence: remainingPence
  };
}

type CoinNoteSizeMapping = { [key: string]: number };

// Mapping of Bank of England GBP coin values to their diameters, in hundredths of millimetres:
export const coinSizes: CoinNoteSizeMapping = {
  '1p': 2030, // Size of 1p coin
  '2p': 2590, // Size of 2p coin
  '5p': 1800, // Size of 5p coin
  '10p': 2450, // Size of 10p coin
  '20p': 2140, // Size of 20p coin
  '50p': 2730, // Size of 50p coin
  '£1': 2343, // Size of £1 coin
  '£2': 2840 // Size of £2 coin
};

// Mapping of Bank of England GBP note values to their heights, in hundredths of millimetres:
export const poundNoteSizes: CoinNoteSizeMapping = {
  '£5': 6500, // Size of £5 note
  '£10': 6900, // Size of £10 note
  '£20': 7300, // Size of £20 note
  '£50': 7700 // Size of £50 note
};

/**
 * Function to scale a given set of coins. Takes in an array of coins, finds the coin with the biggest diameter,
 * gives it a scale of 1, calculates the scales of smaller coins, and returns an object of these scaled proportions.
 */
export const coinScaledSizes = (coins: string[]): CoinNoteSizeMapping => {
  // Filter out pound notes and other strings from the coins array:
  const filteredCoins = coins.filter(coin => coin in coinSizes);

  // Sort coins in ascending order of their diameters:
  const sortedCoins = [...filteredCoins].sort((a, b) => coinSizes[a] - coinSizes[b]);

  // Determine scaling factor
  const maxCoinValue = sortedCoins[sortedCoins.length - 1];
  const scaleFactor = coinSizes[maxCoinValue];

  // Calculate scaled sizes
  const scaledSizes: CoinNoteSizeMapping = {};
  coins.forEach(coin => {
    if (coin in coinSizes) {
      const scaledSize = coinSizes[coin] / scaleFactor;
      scaledSizes[coin] = scaledSize;
    }
  });

  return scaledSizes;
};

/**
 * Function to scale a given set of GBP pound notes. Takes in an array of notes, finds the note with the biggest height,
 * gives it a scale of 1, calculates the scales of smaller notes, and returns an object of these scaled proportions.
 */
export const poundNoteScaledSizes = (notes: string[]): CoinNoteSizeMapping => {
  // Filter out pound notes and other strings from the coins array:
  const filteredNotes = notes.filter(note => note in poundNoteSizes);

  // Sort notes in ascending order of their heights:
  const sortedNotes = [...filteredNotes].sort((a, b) => poundNoteSizes[a] - poundNoteSizes[b]);

  // Determine scaling factor
  const maxNoteValue = sortedNotes[sortedNotes.length - 1];
  const scaleFactor = poundNoteSizes[maxNoteValue];

  // Calculate scaled sizes
  const scaledSizes: CoinNoteSizeMapping = {};
  notes.forEach(note => {
    if (note in poundNoteSizes) {
      const scaledSize = poundNoteSizes[note] / scaleFactor;
      scaledSizes[note] = scaledSize;
    }
  });

  return scaledSizes;
};

/**
 * Function to scale a given set of GBP notes and coins. Takes in an array of notes and coins, finds the note with the biggest height,
 * gives it a scale of 1, calculates the scales of smaller notes, then scales the accordingly against the smallest note's height.
 * If no notes are passed, coins are just scaled from a base of 1.
 */
export const moneyScaledSizes = (money: string[]) => {
  // Filter out pound notes and other strings from the coins array:
  const [filteredNotes, filteredCoins] = [
    money.filter(denom => denom in poundNoteSizes),
    money.filter(denom => denom in coinSizes)
  ];

  const noteScales = poundNoteScaledSizes(filteredNotes);

  const coinScales = coinScaledSizes(filteredCoins);

  // This assumes that the coins will be scaled to be the same size as the smallest note that is passed in:
  const smallestNoteScale = Math.min(...Object.values(noteScales));

  // smallestNoteScale will be Infinity if no notes are passed - in this case, assume a scale of 1:
  const noteScaleToScaleCoinsAgainst = smallestNoteScale === Infinity ? 1 : smallestNoteScale;

  // Adjust coin scales by multiplying each value by `noteScaleToScaleCoinsAgainst`
  const adjustedCoinScales: CoinNoteSizeMapping = {};
  for (const [coin, scale] of Object.entries(coinScales)) {
    adjustedCoinScales[coin] = scale * noteScaleToScaleCoinsAgainst;
  }

  return {
    ...noteScales,
    ...adjustedCoinScales
  };
};

/**
 * Function that takes in an array of monies as strings for e.g ['10p', '50p', '£1']
 * Loops over each money in the array and renders the relevant money SVG.
 * Note Images use double the width.
 * Optional scaledCoins prop calls into the coinScaledSizes util function, scaling all the sizes of the coins correctly.
 * Optional scaledNotes prop calls into the notesScaledSizes util function, scaling all the sizes of the notes correctly.
 * Coins will be scaled against the height of the smallest note.
 */
export function displayMoney(
  monies: string[],
  width = 100,
  height = 100,
  scaledCoins = false,
  scaledNotes = false
) {
  const scaledCoinSizes = scaledCoins
    ? coinScaledSizes(monies)
    : {
        '1p': 1,
        '2p': 1,
        '5p': 1,
        '10p': 1,
        '20p': 1,
        '50p': 1,
        '£1': 1,
        '£2': 1
      };

  const scaledNoteSizes = scaledNotes
    ? poundNoteScaledSizes(monies)
    : {
        '£5': 1,
        '£10': 1,
        '£20': 1,
        '£50': 1
      };

  // This assumes that the coins will be scaled to be the same size as the smallest note that is passed in:
  const smallestNoteScale = Math.min(...Object.values(scaledNoteSizes));

  // smallestNoteScale will be Infinity if scaledNotes is true and no notes are passed - in this case, assume a scale of 1:
  const noteScaleToScaleCoinsAgainst = smallestNoteScale === Infinity ? 1 : smallestNoteScale;

  return monies.map((money, idx) => {
    switch (money) {
      case '1p':
        return (
          <AssetSvg
            name="Money/Pence1"
            height={height * scaledCoinSizes['1p'] * noteScaleToScaleCoinsAgainst}
            width={width * scaledCoinSizes['1p'] * noteScaleToScaleCoinsAgainst}
            key={idx}
          />
        );
      case '2p':
        return (
          <AssetSvg
            name="Money/Pence2"
            height={height * scaledCoinSizes['2p'] * noteScaleToScaleCoinsAgainst}
            width={width * scaledCoinSizes['2p'] * noteScaleToScaleCoinsAgainst}
            key={idx}
          />
        );
      case '5p':
        return (
          <AssetSvg
            name="Money/Pence5"
            height={height * scaledCoinSizes['5p'] * noteScaleToScaleCoinsAgainst}
            width={width * scaledCoinSizes['5p'] * noteScaleToScaleCoinsAgainst}
            key={idx}
          />
        );
      case '10p':
        return (
          <AssetSvg
            name="Money/Pence10"
            height={height * scaledCoinSizes['10p'] * noteScaleToScaleCoinsAgainst}
            width={width * scaledCoinSizes['10p'] * noteScaleToScaleCoinsAgainst}
            key={idx}
          />
        );
      case '20p':
        return (
          <AssetSvg
            name="Money/Pence20"
            height={height * scaledCoinSizes['20p'] * noteScaleToScaleCoinsAgainst}
            width={width * scaledCoinSizes['20p'] * noteScaleToScaleCoinsAgainst}
            key={idx}
          />
        );
      case '50p':
        return (
          <AssetSvg
            name="Money/Pence50"
            height={height * scaledCoinSizes['50p'] * noteScaleToScaleCoinsAgainst}
            width={width * scaledCoinSizes['50p'] * noteScaleToScaleCoinsAgainst}
            key={idx}
          />
        );
      case '£1':
        return (
          <AssetSvg
            name="Money/Pounds1"
            height={height * scaledCoinSizes['£1'] * noteScaleToScaleCoinsAgainst}
            width={width * scaledCoinSizes['£1'] * noteScaleToScaleCoinsAgainst}
            key={idx}
          />
        );
      case '£2':
        return (
          <AssetSvg
            name="Money/Pounds2"
            height={height * scaledCoinSizes['£2'] * noteScaleToScaleCoinsAgainst}
            width={width * scaledCoinSizes['£2'] * noteScaleToScaleCoinsAgainst}
            key={idx}
          />
        );
      case '£5':
        return (
          <PngImage
            key={idx}
            resizeMode="contain"
            source={require('../assets/images/Pounds5.png')}
            style={{
              width: width * 2 * scaledNoteSizes['£5'],
              height: height * scaledNoteSizes['£5']
            }}
          />
        );
      case '£10':
        return (
          <PngImage
            key={idx}
            resizeMode="contain"
            source={require('../assets/images/Pounds10.png')}
            style={{
              width: width * 2 * scaledNoteSizes['£10'],
              height: height * scaledNoteSizes['£10']
            }}
          />
        );
      case '£20':
        return (
          <PngImage
            key={idx}
            resizeMode="contain"
            source={require('../assets/images/Pounds20.png')}
            style={{
              width: width * 2 * scaledNoteSizes['£20'],
              height: height * scaledNoteSizes['£20']
            }}
          />
        );
      case '£50':
        return (
          <PngImage
            key={idx}
            resizeMode="contain"
            source={require('../assets/images/Pounds50.png')}
            style={{
              width: width * 2 * scaledNoteSizes['£50'],
              height: height * scaledNoteSizes['£50']
            }}
          />
        );
    }
  });
}

/**
 * Function that takes in a total amount of pence and converts this into words
 * Limited to English only
 * Will always return words in lowercase.
 */
export function currencyToWords(pence: number) {
  const pounds = Math.floor(pence / 100);
  const remainingPence = pence % 100;

  const poundsAsWords = pounds > 0 ? `${integerToWord(pounds)} pound${pounds > 1 ? 's' : ''}` : '';
  const penceAsWords = remainingPence > 0 ? `${integerToWord(remainingPence)} pence` : '';
  const poundsAndPence = pounds > 0 && remainingPence > 0 ? ' and ' : '';

  return `${poundsAsWords}${poundsAndPence}${penceAsWords}`;
}

/**
 * Function to determine if a user answer is a valid money answer, returning a boolean.
 * This checks that any leading zeroes or decimal places are used correctly.
 */
export function isValidMoneyAnswer(userAnswer: string) {
  // If the string includes a decimal place, there must be exactly two decimal places,
  // i.e. there must be two characters after the decimal place.
  const decimalCheck = userAnswer.includes('.') ? userAnswer.split('.')[1].length === 2 : true;

  // If the first character is a zero, the next character must be a decimal point to be valid.
  const leadingZeroesCheck = userAnswer[0] === '0' ? (userAnswer[1] === '.' ? true : false) : true;

  return decimalCheck && leadingZeroesCheck;
}

export const numberToPennyPoundOrPence = (
  money: number,
  translate: TranslationFunctions,
  removePluralPounds: boolean = false
) => {
  if (money === 1) {
    return translate.units.numberOfPenny(money);
  } else if (money >= 200 && removePluralPounds) {
    return `${money / 100} ${translate.units.pounds(1)}`;
  } else if (money < 100) {
    return translate.units.numberOfPence(money);
  } else {
    return translate.units.numberOfPounds(money / 100);
  }
};
