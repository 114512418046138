import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomFromArrayWithWeights,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  shuffle
} from '../../../../utils/random';
import Text from '../../../../components/typography/Text';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import { numberEnum } from '../../../../utils/zod';
import { View } from 'react-native';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';
import { countRange, nestedArrayHasNoDuplicates } from '../../../../utils/collections';
import { MarkupAssets } from '../../../../markup';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';

////
// Questions
////

const shapes = [
  'Heart',
  'Face',
  'Arrow',
  'Equilateral',
  'Isosceles',
  'Trapezium',
  'Pentagon',
  'Rectangle',
  'RA'
] as const;
type Shape = (typeof shapes)[number];

const svgNames: Record<Shape, SvgName[]> = {
  Heart: [
    'SymmetricalShapes/vertical1_green',
    'SymmetricalShapes/vertical1_pink',
    'SymmetricalShapes/vertical1_purple',
    'SymmetricalShapes/vertical1_yellow'
  ],
  Face: [
    'SymmetricalShapes/vertical2_green',
    'SymmetricalShapes/vertical2_pink',
    'SymmetricalShapes/vertical2_purple',
    'SymmetricalShapes/vertical2_yellow'
  ],
  Arrow: [
    'SymmetricalShapes/horizontal2_green',
    'SymmetricalShapes/horizontal2_pink',
    'SymmetricalShapes/horizontal2_purple',
    'SymmetricalShapes/horizontal2_yellow'
  ],
  Equilateral: [
    'Equilateral_triangles/triangle_equal_green',
    'Equilateral_triangles/triangle_equal_pink',
    'Equilateral_triangles/triangle_equal_purple',
    'Equilateral_triangles/triangle_equal_yellow'
  ],
  Isosceles: [
    'Isosceles_triangles_narrow/triangle_isos_narrow_green',
    'Isosceles_triangles_narrow/triangle_isos_narrow_purple',
    'Isosceles_triangles_narrow/triangle_isos_narrow_pink',
    'Isosceles_triangles_narrow/triangle_isos_narrow_yellow'
  ],
  RA: [
    'Right_angled_triangles/triangle_RA_green',
    'Right_angled_triangles/triangle_RA_pink',
    'Right_angled_triangles/triangle_RA_purple',
    'Right_angled_triangles/triangle_RA_yellow'
  ],
  Trapezium: [
    'Trapezium/trapezium_isosceles_green',
    'Trapezium/trapezium_isosceles_purple',
    'Trapezium/trapezium_isosceles_pink',
    'Trapezium/trapezium_isosceles_yellow'
  ],
  Pentagon: [
    'Pentagon_houses/pentagon_house_green',
    'Pentagon_houses/pentagon_house_purple',
    'Pentagon_houses/pentagon_house_pink',
    'Pentagon_houses/pentagon_house_yellow'
  ],
  Rectangle: [
    'Rectangle/rectangle_green',
    'Rectangle/rectangle_purple',
    'Rectangle/rectangle_pink',
    'Rectangle/rectangle_yellow'
  ]
};

const Question1 = newQuestionContent({
  uid: 'blJ',
  description: 'blJ',
  keywords: ['Pattern', 'Clockwise', 'Anti-clockwise', 'Quarter', 'Half'],
  schema: z.object({
    shape: z.enum([
      'Heart',
      'Face',
      'Arrow',
      'Equilateral',
      'Isosceles',
      'Trapezium',
      'Pentagon',
      'RA'
    ]),
    colorIndex: z.number().int().min(0).max(3),
    startingRotation: numberEnum([0, 90, 180, 270]),
    rotation: numberEnum([90, 180, 270]),
    options: numberEnum([0, 90, 180, 270]).array().length(4)
  }),
  simpleGenerator: () => {
    const shape = getRandomFromArray([
      'Heart',
      'Face',
      'Arrow',
      'Equilateral',
      'Isosceles',
      'Trapezium',
      'Pentagon',
      'RA'
    ] as const);
    const colorIndex = randomIntegerInclusive(0, 3);
    const startingRotation = getRandomFromArrayWithWeights(
      [shape === 'Arrow' ? 270 : 0, shape === 'Arrow' ? 0 : 90, 180, 270] as const,
      [3, 1, 1, 1]
    );
    const rotation = getRandomFromArray([90, 180, 270] as const);

    return {
      shape,
      colorIndex,
      startingRotation,
      rotation,
      options: shuffle([0, 90, 180, 270] as const)
    };
  },
  Component: props => {
    const {
      question: { shape, colorIndex, startingRotation, rotation, options },
      translate
    } = props;

    const svgName = svgNames[shape][colorIndex];

    return (
      <MarkupAssets
        elements={{
          shapeA: (
            <View
              style={{
                transform: `rotate(${startingRotation}deg)`
              }}
            >
              <AssetSvg key="shapeA" name={svgName} height={110} width={110} />
            </View>
          ),
          shapeB: (
            <View
              style={{
                transform: `rotate(${startingRotation + rotation}deg)`
              }}
            >
              <AssetSvg key="shapeA" name={svgName} height={110} width={110} />
            </View>
          )
        }}
      >
        <QF37SentenceDrag
          actionPanelVariant="bottomMid"
          title={translate.ks1Instructions.dragTheNextTwoShapesToContinueThePattern()}
          pdfTitle={translate.ks1PDFInstructions.drawTheNextTwoShapesToContinueThePattern()}
          items={options.map(val => ({
            value: val,
            component: (
              <View
                style={{
                  transform: `rotate(${startingRotation + val}deg)`
                }}
              >
                <AssetSvg name={svgName} height={110} width={110} />
              </View>
            )
          }))}
          itemVariant="pdfSquare"
          pdfLayout="itemsHidden"
          sentence={`${countRange(5)
            .map(val => (val % 2 === 0 ? '<asset name="shapeA" />' : '<asset name="shapeB" />'))
            .join('  ')}   <ans/>   <ans/>`}
          testCorrect={[rotation, 0]}
        />
      </MarkupAssets>
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'blK',
  description: 'blK',
  keywords: ['Half turn', 'Quarter turn', 'Clockwise', 'Anti-clockwise'],
  schema: z.object({
    shape: z.enum([
      'Heart',
      'Face',
      'Arrow',
      'Equilateral',
      'Isosceles',
      'Trapezium',
      'Pentagon',
      'Rectangle',
      'RA'
    ]),
    colorIndex: z.number().int().min(0).max(3),
    startingRotation: numberEnum([0, 90, 180, 270]),
    rotation: numberEnum([-90, 90, 180]),
    options: z.enum(['quarter', 'half', 'antiClockwise', 'clockwise']).array().length(4)
  }),
  simpleGenerator: () => {
    const shape = getRandomFromArray([
      'Heart',
      'Face',
      'Arrow',
      'Equilateral',
      'Isosceles',
      'Trapezium',
      'Pentagon',
      'Rectangle',
      'RA'
    ] as const);
    const colorIndex = randomIntegerInclusive(0, 3);
    const startingRotation = getRandomFromArrayWithWeights(
      [shape === 'Arrow' ? 270 : 0, shape === 'Arrow' ? 0 : 90, 180, 270] as const,
      [3, 1, 1, 1]
    );
    const rotation =
      shape === 'Equilateral'
        ? 180
        : shape === 'Rectangle'
        ? 90
        : getRandomFromArray([-90, 180, 90] as const);

    return {
      shape,
      colorIndex,
      startingRotation,
      rotation,
      options: shuffle(['quarter', 'half', 'antiClockwise', 'clockwise'] as const)
    };
  },
  Component: props => {
    const {
      question: { shape, colorIndex, startingRotation, rotation, options },
      translate
    } = props;

    const svgName = svgNames[shape][colorIndex];
    const isHalf = rotation === 180;
    const isAnti = rotation < 0;

    const answerArrays = isHalf
      ? [['half']]
      : [
          ['quarter'],
          shape === 'Rectangle'
            ? ['antiClockwise', 'clockwise']
            : isAnti
            ? ['antiClockwise']
            : ['clockwise']
        ];

    return (
      <QF36ContentAndSentenceDrag
        title={translate.ks1Instructions.dragTheCardsToCompleteTheSentence()}
        pdfTitle={translate.ks1PDFInstructions.useCardsCompleteSentence()}
        items={options.map(val => ({
          value: val,
          component: (
            <Text variant="WRN700" style={{ fontSize: 40 }}>
              {translate.ks1MiscStrings.directions[val]()}
            </Text>
          )
        }))}
        itemVariant="rectangle"
        sentence={
          isHalf
            ? translate.ks1AnswerSentences.theShapeMakesAAnsTurnEachTime()
            : translate.ks1AnswerSentences.theShapeMakesAAnsTurnAnsEachTime()
        }
        testCorrect={userAnswer => {
          return userAnswer.every((val, id) => answerArrays[id].includes(val ?? ''));
        }}
        customMarkSchemeAnswer={{
          answersToDisplay: [answerArrays.map(val => [val[0]]).flat()],
          answerText:
            shape === 'Rectangle' && !isHalf
              ? translate.markScheme.allowClockwiseOrAntiClockwise()
              : undefined
        }}
        pdfItemVariant="rectangle"
        questionHeight={1000}
        sentencesStyle={{ alignItems: 'flex-start' }}
        Content={({ dimens }) => (
          <View style={{ flexDirection: 'row', gap: 20 }}>
            {countRange(5).map(val => (
              <View
                key={val}
                style={{ transform: `rotate(${startingRotation + rotation * val}deg)` }}
              >
                <AssetSvg name={svgName} height={dimens.width * 0.15} width={dimens.width * 0.15} />
              </View>
            ))}
          </View>
        )}
      />
    );
  },
  questionHeight: 1000
});

const Question3 = newQuestionContent({
  uid: 'blL',
  description: 'blL',
  keywords: ['Pattern', 'Clockwise', 'Anti-clockwise', 'Quarter', 'Half'],
  schema: z.object({
    shapes: z
      .object({
        shape: z.enum([
          'Heart',
          'Face',
          'Arrow',
          'Equilateral',
          'Isosceles',
          'Trapezium',
          'Pentagon',
          'Rectangle',
          'RA'
        ]),
        colorIndex: z.number().int().min(0).max(3),
        rotation: numberEnum([0, 90, 180, 270])
      })
      .array()
      .length(2),
    isTwoAThenB: z.boolean(),
    answerIndex: z.number().int().min(0).max(3),
    options: z
      .object({
        shapeARotation: numberEnum([0, 90, 180, 270]),
        shapeBRotation: numberEnum([0, 90, 180, 270]),
        value: z.enum(['A', 'B', 'C', 'D'])
      })
      .array()
      .length(4)
      .refine(
        val =>
          nestedArrayHasNoDuplicates(
            val.map(obj => [obj.shapeARotation, obj.shapeBRotation]),
            true
          ),
        'no duplicate options'
      )
  }),
  simpleGenerator: () => {
    const shape = getRandomSubArrayFromArray(
      [
        'Heart',
        'Face',
        'Arrow',
        'Equilateral',
        'Isosceles',
        'Trapezium',
        'Pentagon',
        'Rectangle',
        'RA'
      ] as const,
      2
    );
    const colorIndexes = randomUniqueIntegersInclusive(0, 3, 2);

    const isTwoAThenB = getRandomBoolean();

    // Which shape gets rotated. AAB - only 1 shape can be rotated i.e both As or B
    const variant = isTwoAThenB
      ? getRandomFromArray([[0], [1]])
      : getRandomFromArray([[0], [1], [0, 1]]);

    const shapes = shape.map((shape, i) => ({
      shape,
      colorIndex: colorIndexes[i],
      rotation: variant.includes(i)
        ? // Rectangles can only do quarter turns and direction is irrelevant
          shape === 'Rectangle'
          ? 90
          : getRandomFromArray([90, 180, 270] as const)
        : (0 as const)
    }));

    // If AAB, the answer box can be in the first or last section of the sequence
    const answerIndex = isTwoAThenB
      ? getRandomFromArray([0, 2] as const)
      : randomIntegerInclusive(0, 3);

    const options = rejectionSample(
      () => {
        const a = shapes[0].rotation * answerIndex;
        const b = shapes[1].rotation * answerIndex;

        const correctAnswer = {
          shapeARotation: (a > 180 ? a % 360 : a) as 90 | 180 | 0 | 270,
          shapeBRotation: (b > 180 ? b % 360 : b) as 90 | 180 | 0 | 270,
          value: 'A' as const
        };

        const otherRotations = countRange(3).map(i => ({
          // Use only 2 angles for rectangles as they are symmetrical
          shapeARotation:
            shapes[0].shape === 'Rectangle'
              ? (getRandomFromArray([
                  correctAnswer.shapeARotation,
                  correctAnswer.shapeARotation === 270 ? 0 : correctAnswer.shapeARotation + 90
                ]) as 90 | 180 | 0 | 270)
              : getRandomFromArray([90, 180, 0, 270] as const),
          shapeBRotation:
            shapes[1].shape === 'Rectangle'
              ? (getRandomFromArray([
                  correctAnswer.shapeBRotation,
                  correctAnswer.shapeBRotation === 270 ? 0 : correctAnswer.shapeBRotation + 90
                ]) as 90 | 180 | 0 | 270)
              : getRandomFromArray([90, 180, 0, 270] as const),
          value: (['B', 'C', 'D'] as const)[i]
        }));

        return [correctAnswer, ...otherRotations];
      },
      val =>
        nestedArrayHasNoDuplicates(
          val.map(obj => [obj.shapeARotation, obj.shapeBRotation]),
          true
        )
    );

    return {
      shapes,
      isTwoAThenB,
      answerIndex,
      options: shuffle(options)
    };
  },
  Component: props => {
    const {
      question: { shapes, isTwoAThenB, answerIndex, options },
      translate,
      displayMode
    } = props;

    const size = displayMode === 'digital' ? 70 : 120;

    const elements = countRange(isTwoAThenB ? 3 : 4).map(val => (
      <View key={val} style={{ flexDirection: 'row', gap: 20 }}>
        <View
          style={{
            transform: `rotate(${shapes[0].rotation * val}deg)`
          }}
        >
          <AssetSvg
            name={svgNames[shapes[0].shape][shapes[0].colorIndex]}
            height={size}
            width={size}
          />
        </View>
        {isTwoAThenB && (
          <View
            style={{
              transform: `rotate(${shapes[0].rotation * val}deg)`
            }}
          >
            <AssetSvg
              name={svgNames[shapes[0].shape][shapes[0].colorIndex]}
              height={size}
              width={size}
            />
          </View>
        )}
        <View
          style={{
            transform: `rotate(${shapes[1].rotation * val}deg)`
          }}
        >
          <AssetSvg
            name={svgNames[shapes[1].shape][shapes[1].colorIndex]}
            height={size}
            width={size}
          />
        </View>
      </View>
    ));

    return (
      <MarkupAssets
        elements={{
          shape0: elements[0],
          shape1: elements[1],
          shape2: elements[2],
          shape3: elements[3],
          otherShape: (
            <View
              style={{
                transform: `rotate(${shapes[0].rotation * answerIndex}deg)`
              }}
            >
              <AssetSvg
                name={svgNames[shapes[0].shape][shapes[0].colorIndex]}
                height={size}
                width={size}
              />
            </View>
          )
        }}
      >
        <QF37SentenceDrag
          title={translate.ks1Instructions.dragTheShapesToCompleteThePattern()}
          pdfTitle={translate.ks1PDFInstructions.tickTheShapesThatFitThePattern()}
          items={options.map(val => ({
            value: val.value,
            component: (
              <View style={{ flexDirection: 'row', gap: 10 }}>
                <View
                  style={{
                    transform: `rotate(${val.shapeARotation}deg)`
                  }}
                >
                  <AssetSvg
                    name={svgNames[shapes[0].shape][shapes[0].colorIndex]}
                    height={size}
                    width={size}
                  />
                </View>
                <View
                  style={{
                    transform: `rotate(${val.shapeBRotation}deg)`
                  }}
                >
                  <AssetSvg
                    name={svgNames[shapes[1].shape][shapes[1].colorIndex]}
                    height={size}
                    width={size}
                  />
                </View>
              </View>
            )
          }))}
          itemVariant="shortRectangle"
          pdfItemVariant="tallRectangle"
          sentence={`${countRange(4)
            .map(val =>
              answerIndex === val
                ? isTwoAThenB
                  ? '<asset name="otherShape" />  <ans/>'
                  : '<ans/>'
                : `<asset name="shape${val}" />`
            )
            .join('  ')}`}
          testCorrect={['A']}
        />
      </MarkupAssets>
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'ShapePatternsWithTurns',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question3]
});
export default SmallStep;
