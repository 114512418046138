import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { numbersExchange } from '../../../../utils/exchanges';
import { getRandomFromArrayWithWeights, randomIntegerInclusive } from '../../../../utils/random';
import { buildSimpleNumberSentence } from '../../../../utils/strings';
import { SUB } from '../../../../constants';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { SimpleBaseTenWithCrossOut } from '../../../../components/question/representations/Base Ten/SimpleBaseTenWithCrossOut';
import { range, rangeAsString } from '../../../../utils/collections';
import NumberLine from '../../../../components/question/representations/Number Line/NumberLine';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';

////
// Questions
////

////
// Small Step
////

const Question1 = newQuestionContent({
  uid: 'bg5',
  description: 'bg5',
  keywords: ['Subtract', 'Base 10', 'Across a ten'],
  schema: z
    .object({
      minuend: z.number().int().min(11).max(98),
      subtrahend: z.number().int().min(2).max(9)
    })
    .refine(
      val => numbersExchange(val.minuend, -val.subtrahend),
      'Numbers must exchange into previous tens'
    )
    .refine(val => val.minuend % 10 !== 0, 'Minuend must not be a multiple of 10'),
  simpleGenerator: () => {
    const subtrahend = randomIntegerInclusive(2, 9);
    const minuend = randomIntegerInclusive(11, 98, {
      constraint: x => numbersExchange(x, -subtrahend) && x % 10 !== 0
    });

    return { minuend, subtrahend };
  },
  Component: props => {
    const {
      question: { minuend, subtrahend },
      translate
    } = props;

    const { sentence, answer } = buildSimpleNumberSentence(
      [minuend, subtrahend, minuend - subtrahend],
      SUB,
      2
    );

    const numberOfTens = Math.floor(minuend / 10) - 1;
    const numberOfOnes = (minuend % 10) + 10;

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.completeTheSubtraction()}
        sentence={sentence}
        pdfDirection="column"
        testCorrect={[answer.toString()]}
        Content={({ dimens }) => (
          <SimpleBaseTenWithCrossOut
            ones={numberOfOnes}
            tens={numberOfTens}
            crossedOutIndices={{
              ones: range(numberOfOnes - 1, numberOfOnes - subtrahend),
              tens: []
            }}
            dimens={{ width: dimens.width, height: dimens.height * 0.8 }}
          />
        )}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question2 = newQuestionContent({
  uid: 'bg6',
  description: 'bg6',
  keywords: ['Subtract', 'Number line', 'Across a ten'],
  schema: z.object({
    minuend: z.number().int().min(11).max(98),
    subtrahend: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    const subtrahend = randomIntegerInclusive(1, 9);
    const minuend = randomIntegerInclusive(11, 98, {
      constraint: x => numbersExchange(x, -subtrahend)
    });

    return { subtrahend, minuend };
  },
  Component: props => {
    const {
      question: { subtrahend, minuend },
      translate
    } = props;

    const { sentence, answer } = buildSimpleNumberSentence(
      [minuend, subtrahend, minuend - subtrahend],
      SUB,
      2
    );

    const tickValues = rangeAsString(minuend - 10, minuend + 1, 1, true);

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.completeTheSubtraction()}
        pdfDirection="column"
        Content={({ dimens }) => <NumberLine tickValues={tickValues} dimens={dimens} />}
        sentence={sentence}
        testCorrect={[answer.toString()]}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bg7',
  description: 'bg7',
  keywords: ['Subtract', 'Across a ten'],
  schema: z.object({
    minuend: z.number().int().min(11).max(98),
    subtrahend: z.number().int().min(1).max(9),
    reversed: z.boolean()
  }),
  simpleGenerator: () => {
    const subtrahend = randomIntegerInclusive(1, 9);
    const minuend = randomIntegerInclusive(11, 98, {
      constraint: x => numbersExchange(x, -subtrahend)
    });

    const reversed = getRandomFromArrayWithWeights([true, false], [1, 3]);

    return { subtrahend, minuend, reversed };
  },
  Component: props => {
    const {
      question: { subtrahend, minuend, reversed },
      translate
    } = props;

    const { sentence, answer } = buildSimpleNumberSentence(
      [minuend, subtrahend, minuend - subtrahend],
      SUB,
      2,
      { reversed }
    );

    return (
      <QF2AnswerBoxOneSentence
        title={translate.ks1Instructions.completeTheSubtraction()}
        sentence={sentence}
        testCorrect={[answer.toString()]}
        questionHeight={600}
      />
    );
  },
  questionHeight: 600
});

const SmallStep = newSmallStepContent({
  smallStep: 'SubtractA1DigitNumberFromA2DigitNumberAcrossA10',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
