import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomFromArray,
  getRandomFromArrayWithWeights,
  randomIntegerInclusive
} from '../../../../utils/random';
import { base10ObjectToNumber } from '../../../../utils/math';
import QF3InteractiveContent from '../../../../components/question/questionFormats/QF3InteractiveContent';
import { PartWholeModel } from '../../../../components/question/representations/Part Whole Model/PartWholeModel';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bfY',
  description: 'bfY',
  keywords: ['Partition', 'Part-whole', 'Tens', 'Ones'],
  schema: z.object({
    ones: z.number().int().min(1).max(9),
    tens: z.number().int().min(2).max(9),
    isTensLHS: z.boolean()
  }),
  simpleGenerator: () => {
    const ones = randomIntegerInclusive(1, 9);
    const tens = randomIntegerInclusive(2, 9);
    const isTensLHS = getRandomFromArrayWithWeights([true, false], [4, 1]);

    return { ones, tens, isTensLHS };
  },
  Component: props => {
    const {
      question: { ones, tens, isTensLHS },
      translate
    } = props;

    const number = base10ObjectToNumber({ ones, tens });
    const partition = isTensLHS
      ? [(tens * 10).toLocaleString(), ones.toLocaleString()]
      : [ones.toLocaleString(), (tens * 10).toLocaleString()];

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.usePartWholeModelToCompleteSentence()}
        testCorrect={[tens.toString(), ones.toString()]}
        sentence={translate.ks1AnswerSentences.numEqualsAnsTensAnsOnes(number, tens, ones)}
        Content={({ dimens }) => (
          <PartWholeModel top={number} partition={partition} dimens={dimens} />
        )}
        pdfDirection="column"
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'bfZ',
  description: 'bfZ',
  keywords: ['Partition', 'Part-whole', 'Tens', 'Ones'],
  schema: z.object({
    ones: z.number().int().min(1).max(9),
    tens: z.number().int().min(2).max(9),
    unitOfAnswer: z.enum(['ones', 'tens']),
    isTensLHS: z.boolean()
  }),
  simpleGenerator: () => {
    const unitOfAnswer = getRandomFromArray(['ones', 'tens'] as const);
    const ones = randomIntegerInclusive(1, 9);
    const tens = randomIntegerInclusive(2, 9);
    const isTensLHS = getRandomFromArrayWithWeights([true, false], [4, 1]);

    return { ones, tens, unitOfAnswer, isTensLHS };
  },
  Component: props => {
    const {
      question: { ones, tens, unitOfAnswer, isTensLHS },
      translate
    } = props;

    const number = base10ObjectToNumber({ ones, tens });
    let partition: (string | '$ans')[];
    let correctAnswer: number;

    if (unitOfAnswer === 'tens') {
      partition = isTensLHS ? ['$ans', ones.toLocaleString()] : [ones.toLocaleString(), '$ans'];
      correctAnswer = tens * 10;
    } else {
      partition = isTensLHS
        ? [(tens * 10).toLocaleString(), '$ans']
        : ['$ans', (tens * 10).toLocaleString()];
      correctAnswer = ones;
    }
    return (
      <QF3InteractiveContent
        title={translate.ks1Instructions.completeThePartWholeModel()}
        inputType="numpad"
        initialState={['']}
        Content={({ userAnswer, setUserAnswer, dimens }) => (
          <PartWholeModel
            userAnswer={userAnswer}
            onTextInput={(answer, index) => {
              const newArr = [...userAnswer];
              newArr[index] = answer;
              setUserAnswer(newArr);
            }}
            top={number}
            partition={partition}
            isInteractive
            dimens={dimens}
          />
        )}
        testCorrect={userAnswer => userAnswer[0] === correctAnswer.toString()}
        testComplete={userAnswer => userAnswer.every(it => it !== '')}
        customMarkSchemeAnswer={{ answersToDisplay: [correctAnswer.toLocaleString()] }}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question3 = newQuestionContent({
  uid: 'bf0',
  description: 'bf0',
  keywords: ['Tens', 'Ones'],
  schema: z.object({
    tens: z.number().int().min(2).max(5),
    ones: z.number().int().min(0).max(9),
    sentenceLayout: z.enum(['ansTensOnes', 'ansOnesTens', 'numAnsTensAnsOnes', 'numAnsOnesAnsTens'])
  }),
  simpleGenerator: () => {
    const tens = randomIntegerInclusive(2, 5);
    const ones = tens === 5 ? 0 : randomIntegerInclusive(0, 9);
    const sentenceLayout = getRandomFromArrayWithWeights(
      ['ansTensOnes', 'ansOnesTens', 'numAnsTensAnsOnes', 'numAnsOnesAnsTens'] as const,
      [4, 1, 4, 1]
    );
    return { tens, ones, sentenceLayout };
  },
  Component: props => {
    const {
      question: { tens, ones, sentenceLayout },
      translate
    } = props;

    const number = base10ObjectToNumber({ ones, tens });
    const [answer, sentence] = (() => {
      switch (sentenceLayout) {
        case 'ansOnesTens':
          return [[number], translate.ks1AnswerSentences.ansHasNumTensAndNumOnes(tens, ones)];
        case 'ansTensOnes':
          return [[number], translate.ks1AnswerSentences.ansHasNumOnesAndNumTens(ones, tens)];
        case 'numAnsOnesAnsTens':
          return [
            [ones, tens],
            translate.ks1AnswerSentences.numHasAnsOnesAndAnsTens(number, ones, tens)
          ];
        case 'numAnsTensAnsOnes':
          return [
            [tens, ones],
            translate.ks1AnswerSentences.numHasAnsTensAndAnsOnes(number, tens, ones)
          ];
      }
    })();
    return (
      <QF2AnswerBoxOneSentence
        title={translate.ks1Instructions.completeTheNumberSentence()}
        sentence={sentence}
        testCorrect={answer.map(ans => ans.toString())}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'PartitionNumbersTo100',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question3]
});
export default SmallStep;
