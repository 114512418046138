import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import { z } from 'zod';
import { numberEnum } from '../../../../utils/zod';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import Text from '../../../../components/typography/Text';
import AnglesAroundAPoint from '../../../../components/question/representations/AnglesAroundAPoint';
import { sumNumberArray } from '../../../../utils/collections';
import {
  algebraicSymbolSchema,
  getAlgebraicSymbol,
  getAlgebraicSymbolPair
} from '../../../../utils/algebraicSymbols';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { DEGREES } from '../../../../constants';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aD4',
  description: 'aD4',
  questionHeight: 1000,
  keywords: ['Angles', 'Right angle', 'Straight line', 'Reflex'],
  schema: z.object({
    angles: z.number().int().min(15).max(345).array().length(2),
    options: z.array(numberEnum([90, 180, 360, 270, 45])).length(4)
  }),
  simpleGenerator: () => {
    const totalAngle = getRandomFromArray([90, 180, 360] as const);

    const angles = [];
    switch (totalAngle) {
      case 90: {
        const angle1 = randomIntegerInclusive(15, 75);
        angles.push(angle1, 90 - angle1);
        break;
      }
      case 180: {
        const angle1 = randomIntegerInclusive(15, 85);
        angles.push(angle1, 180 - angle1);
        break;
      }
      case 360: {
        const angle1 = randomIntegerInclusive(15, 85);
        angles.push(angle1, 360 - angle1);
        break;
      }
    }

    const options = shuffle([
      90 as const,
      180 as const,
      360 as const,
      getRandomFromArray([270, 45] as const)
    ]);

    return { angles, options };
  },

  Component: props => {
    const {
      question: { angles, options },
      translate
    } = props;

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.selectSumOfAngles()}
        pdfTitle={translate.instructions.circleSumOfAngles()}
        testCorrect={[sumNumberArray(angles)]}
        numItems={4}
        mainPanelContainer={{ justifyContent: 'space-evenly' }}
        itemLayout="row"
        questionHeight={1000}
        Content={({ dimens }) => (
          <AnglesAroundAPoint
            radius={Math.min(dimens.width * 0.4, dimens.height * 0.4)}
            angles={[{ angle: angles[0] }, { angle: angles[1] }]}
            arcSizeGroups={[[0], [1]]}
          />
        )}
        renderItems={options.map(val => ({
          value: val,
          component: <Text variant="WRN700">{`${val.toLocaleString()}${DEGREES}`}</Text>
        }))}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aD5',
  description: 'aD5',
  keywords: ['Angles', 'Right angle', 'Calculate'],
  schema: z.object({
    angle: z.number().int().min(36).max(69),
    letter: algebraicSymbolSchema
  }),
  simpleGenerator: () => {
    const angle = randomIntegerInclusive(36, 69, { constraint: x => x % 5 !== 0 });
    const letter = getAlgebraicSymbol();

    return { angle, letter };
  },

  Component: props => {
    const {
      question: { angle, letter },
      translate
    } = props;

    return (
      <QF1ContentAndSentence
        sentence={`${letter} = <ans/> ${DEGREES}`}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        title={translate.instructions.workOutSizeOfAngleX(letter)}
        testCorrect={[(90 - angle).toString()]}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        questionHeight={900}
        Content={({ dimens }) => {
          return (
            <AnglesAroundAPoint
              radius={Math.min(dimens.height * 0.7, dimens.width * 0.7)}
              angles={shuffle(
                [
                  { angle: 90 - angle, innerLabel: letter },
                  { angle: angle, innerLabel: `${angle.toLocaleString()}${DEGREES}` }
                ],
                { random: seededRandom(props.question) }
              )}
              arcSizeGroups={[[0], [1]]}
            />
          );
        }}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'aD6',
  description: 'aD6',
  keywords: ['Angles', 'Straight line', 'Calculate'],
  schema: z.object({
    angle: z.number().int().min(36).max(159),
    letter: algebraicSymbolSchema
  }),
  simpleGenerator: () => {
    const angle = randomIntegerInclusive(36, 159, { constraint: x => x % 5 !== 0 });
    const letter = getAlgebraicSymbol();

    return { angle, letter };
  },

  Component: props => {
    const {
      question: { angle, letter },
      translate
    } = props;

    return (
      <QF1ContentAndSentence
        sentence={`${letter} = <ans/> ${DEGREES}`}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        title={translate.instructions.workOutSizeOfAngleX(letter)}
        testCorrect={[(180 - angle).toString()]}
        Content={({ dimens }) => {
          return (
            <AnglesAroundAPoint
              radius={Math.min(dimens.height * 0.7, dimens.width * 0.7)}
              angles={shuffle(
                [
                  { angle: 180 - angle, innerLabel: letter },
                  { angle: angle, innerLabel: `${angle.toLocaleString()}${DEGREES}` }
                ],
                { random: seededRandom(props.question) }
              )}
              arcSizeGroups={[[0], [1]]}
            />
          );
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aD7',
  description: 'aD7',
  keywords: ['Angles', 'Straight line', 'Calculate'],
  schema: z.object({
    angle: z.number().int().min(36).max(159),
    letter: algebraicSymbolSchema
  }),
  simpleGenerator: () => {
    const angle = randomIntegerInclusive(36, 159, { constraint: x => x % 5 !== 0 });
    const letter = getAlgebraicSymbol();

    return { angle, letter };
  },

  Component: props => {
    const {
      question: { angle, letter },
      translate
    } = props;

    return (
      <QF1ContentAndSentence
        sentence={`${letter} = <ans/> ${DEGREES}`}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        title={translate.instructions.workOutSizeOfAngleX(letter)}
        testCorrect={[angle.toString()]}
        Content={({ dimens }) => {
          return (
            <AnglesAroundAPoint
              radius={Math.min(dimens.height * 0.4, dimens.width * 0.4)}
              angles={[
                { angle: angle, innerLabel: `${angle.toLocaleString()}${DEGREES}` },
                { angle: 180 - angle, innerLabel: `${(180 - angle).toLocaleString()}${DEGREES}` },
                { angle: angle, innerLabel: letter },
                { angle: 180 - angle, innerLabel: `${(180 - angle).toLocaleString()}${DEGREES}` }
              ]}
              arcSizeGroups={[
                [0, 2],
                [1, 3]
              ]}
            />
          );
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aD8',
  description: 'aD8',
  keywords: ['Angles', 'Straight line', 'Calculate'],
  schema: z
    .object({
      angle1: z.number().int().min(91).max(169),
      angle2: z.number().int().min(35).max(90),
      angle3: z.number().int().min(36).max(49),
      letter: algebraicSymbolSchema
    })
    .refine(
      val => val.angle1 + val.angle2 + val.angle3 <= 330,
      'all angles should be greater or equal to 30'
    ),
  simpleGenerator: () => {
    const angle1 = randomIntegerInclusive(91, 169, { constraint: x => x % 5 !== 0 });
    const angle2 = randomIntegerInclusive(35, 90);
    const angle3 = randomIntegerInclusive(36, 49, { constraint: x => x % 5 !== 0 });

    const letter = getAlgebraicSymbol();

    return { angle1, angle2, angle3, letter };
  },

  Component: props => {
    const {
      question: { angle1, angle2, angle3, letter },
      translate
    } = props;

    const angle4 = 360 - angle1 - angle2 - angle3;

    return (
      <QF1ContentAndSentence
        sentence={`${letter} = <ans/> ${DEGREES}`}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        title={translate.instructions.workOutSizeOfAngleX(letter)}
        testCorrect={[angle4.toString()]}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        questionHeight={900}
        Content={({ dimens }) => {
          return (
            <AnglesAroundAPoint
              radius={Math.min(dimens.height * 0.4, dimens.width * 0.4)}
              angles={shuffle(
                [
                  { angle: angle1, innerLabel: `${angle1.toLocaleString()}${DEGREES}` },
                  { angle: angle2, innerLabel: `${angle2.toLocaleString()}${DEGREES}` },
                  { angle: angle3, innerLabel: `${angle3.toLocaleString()}${DEGREES}` },
                  { angle: angle4, innerLabel: letter }
                ],
                { random: seededRandom(props.question) }
              )}
              arcSizeGroups={[[0], [1], [2], [3]]}
            />
          );
        }}
      />
    );
  },
  questionHeight: 900
});

/** Archived */
const Question6 = newQuestionContent({
  uid: 'aD9',
  description: 'aD9',
  keywords: ['Angles', 'Straight line', 'Calculate'],
  questionHeight: 1000,
  schema: z.object({
    angleB: z.number().int().min(16).max(34),
    multiplier: z.number().int().min(2).max(5),
    symb1: algebraicSymbolSchema,
    symb2: algebraicSymbolSchema
  }),
  simpleGenerator: () => {
    const [symb1, symb2] = getAlgebraicSymbolPair();
    const angleB = randomIntegerInclusive(16, 34, { constraint: x => x % 5 !== 0 });
    const multiplier = randomIntegerInclusive(2, 5, {
      constraint: x => x * angleB + angleB <= 170
    });

    return { angleB, multiplier, symb1, symb2 };
  },

  Component: props => {
    const {
      question: { angleB, multiplier, symb1, symb2 },
      translate
    } = props;

    const angleA = angleB * multiplier;
    const angleC = 180 - angleA - angleB;

    return (
      <QF1ContentAndSentences
        sentences={[
          `${symb1} = <ans/> ${DEGREES}`,
          `${symb2} = ${angleB.toLocaleString()}${DEGREES}`
        ]}
        style={{ flexDirection: 'row', alignItems: 'center' }}
        pdfSentenceStyle={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-evenly'
        }}
        questionHeight={1000}
        pdfDirection="column"
        title={translate.instructions.angleXIsYTimesAngleZWhatIsSizeOfX(
          symb1,
          multiplier.toLocaleString(),
          symb2
        )}
        testCorrect={[[angleA.toString()], []]}
        Content={({ dimens }) => {
          return (
            <AnglesAroundAPoint
              radius={Math.min(dimens.height * 0.7, dimens.width * 0.7)}
              angles={shuffle(
                [
                  { angle: angleC },
                  { angle: angleA, innerLabel: symb1 },
                  { angle: angleB, innerLabel: symb2 }
                ],
                { random: seededRandom(props.question) }
              )}
              arcSizeGroups={[[0], [1], [2]]}
            />
          );
        }}
      />
    );
  }
});

const Question6v2 = newQuestionContent({
  uid: 'aD92',
  description: 'aD9',
  keywords: ['Angles', 'Straight line', 'Calculate'],
  questionHeight: 1000,
  schema: z.object({
    angleA: z.number().int().min(0).max(360),
    angleB: z.number().int().min(0).max(360),
    angleC: z.number().int().min(0).max(360),
    multiplier: numberEnum([2, 3, 4, 5, 8, 9]),
    symb1: algebraicSymbolSchema,
    symb2: algebraicSymbolSchema
  }),
  simpleGenerator: () => {
    const [symb1, symb2] = getAlgebraicSymbolPair();

    const option = randomIntegerInclusive(1, 3);
    const array = option === 1 ? ([2, 4, 5] as const) : ([2, 3, 4, 5, 8, 9] as const);

    const multiplier = getRandomFromArray(array);

    let angleA;
    let angleB;
    let angleC;
    if (option === 1) {
      angleB = 90 / (multiplier + 1);
      angleA = 90 - angleB;
      angleC = 90;
    } else if (option === 2) {
      angleC = 0;
      angleB = 180 / (multiplier + 1);
      angleA = 180 - angleB;
    } else {
      angleC = 0;
      angleB = 360 / (multiplier + 1);
      angleA = 360 - angleB;
    }

    return { angleA, angleB, angleC, multiplier, symb1, symb2 };
  },

  Component: props => {
    const {
      question: { angleA, angleB, angleC, multiplier, symb1, symb2 },
      translate
    } = props;

    const angles =
      angleC === 0
        ? [
            { angle: angleA, innerLabel: symb1 },
            { angle: angleB, innerLabel: symb2 }
          ]
        : [
            { angle: angleC },
            { angle: angleA, innerLabel: symb1 },
            { angle: angleB, innerLabel: symb2 }
          ];

    const sumOfAngles = angleA + angleB + angleC;

    return (
      <QF1ContentAndSentences
        sentences={[`${symb1} = <ans/> ${DEGREES}`, `${symb2} = <ans/> ${DEGREES}`]}
        style={{ flexDirection: 'row', alignItems: 'center' }}
        pdfSentenceStyle={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-evenly'
        }}
        questionHeight={1000}
        pdfDirection="column"
        title={translate.instructions.angleXIsYTimesAngleZWhatIsSizeOfXAndY(
          symb1,
          multiplier.toLocaleString(),
          symb2
        )}
        testCorrect={[[angleA.toString()], [angleB.toString()]]}
        Content={({ dimens }) => {
          return (
            <AnglesAroundAPoint
              radius={
                sumOfAngles === 360
                  ? Math.min(dimens.height * 0.5, dimens.width * 0.5)
                  : Math.min(dimens.height * 0.7, dimens.width * 0.7)
              }
              angles={angles}
              arcSizeGroups={angles.map((_val, i) => [i])}
            />
          );
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CalculateAngles',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6v2],
  unpublishedQuestionTypes: [],
  archivedQuestionTypes: [Question6]
});
export default SmallStep;
