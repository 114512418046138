import z from 'zod';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  rejectionSample,
  shuffle
} from '../../../../utils/random';
import { AssetSvg } from '../../../../assets/svg';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';
import { arrayHasNoDuplicates, countRange } from '../../../../utils/collections';
import Text from '../../../../components/typography/Text';
import QF14CompleteNumberTrack from '../../../../components/question/questionFormats/QF14CompleteNumberTrack';
import { getObjectSvgName } from '../../../../utils/objectsImages';
import { View } from 'react-native';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import TenFrameLayout from '../../../../components/question/representations/TenFrame/TenFrameLayout';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bdZ',
  description: 'bdZ',
  keywords: ['Times-tables', 'Count', 'Multiplication'],
  schema: z.object({
    multiplier: z.number().int().min(2).max(10),
    item: z.enum(['Apple', 'Cookie', 'Flower', 'Muffin', 'Pear', 'Banana']),
    answers: z.array(z.number().min(2).max(50))
  }),
  simpleGenerator: () => {
    const multiplier = randomIntegerInclusive(2, 10);
    const item = getRandomFromArray([
      'Apple',
      'Cookie',
      'Flower',
      'Muffin',
      'Pear',
      'Banana'
    ] as const);

    const answers = rejectionSample(
      () => [
        multiplier,
        multiplier * 5,
        getRandomBoolean() ? (multiplier - 1) * 5 : (multiplier + 1) * 5
      ],
      val => arrayHasNoDuplicates(val) && val.every(i => i < 51 && i > 0)
    );

    return { multiplier, item, answers: shuffle(answers) };
  },
  Component: props => {
    const {
      question: { multiplier, item, answers },
      translate
    } = props;

    const svgName =
      item === 'Pear'
        ? 'Pears_in_a_transparent_bag/Pears_in_a_bag_5'
        : item === 'Muffin'
        ? 'Food_on_a_plate/Muffins_plate_5'
        : item === 'Banana'
        ? 'Bunches_of_bananas/Bunch_of_bananas_5'
        : getObjectSvgName(item, 5);

    const sentenceLookup: Record<
      string,
      | 'thereAreAnsApplesAltogether'
      | 'thereAreAnsCookiesAltogether'
      | 'thereAreAnsFlowersAltogether'
      | 'thereAreAnsPearsAltogether'
      | 'thereAreAnsMuffinsAltogether'
      | 'thereAreAnsBananasAltogether'
    > = {
      Apple: 'thereAreAnsApplesAltogether',
      Cookie: 'thereAreAnsCookiesAltogether',
      Flower: 'thereAreAnsFlowersAltogether',
      Muffin: 'thereAreAnsMuffinsAltogether',
      Pear: 'thereAreAnsPearsAltogether',
      Banana: 'thereAreAnsBananasAltogether'
    };

    return (
      <QF36ContentAndSentenceDrag
        title={translate.ks1Instructions.dragACardToCompleteTheSentence()}
        pdfTitle={translate.ks1PDFInstructions.useTheCardsToCompleteTheSentence()}
        sentence={translate.ks1AnswerSentences[sentenceLookup[item]]()}
        sentencesStyle={{ alignItems: 'flex-start' }}
        actionPanelVariant="end"
        pdfLayout="itemsTop"
        testCorrect={[multiplier * 5]}
        Content={({ dimens }) => (
          <View style={{ flexDirection: 'column', gap: 25 }}>
            <View style={{ flexDirection: 'row', gap: 25 }}>
              {countRange(Math.min(multiplier, 6)).map(i => (
                <AssetSvg
                  key={i}
                  name={svgName}
                  width={dimens.width / 7}
                  height={dimens.height / 3}
                />
              ))}
            </View>
            <View style={{ flexDirection: 'row', gap: 25, alignItems: 'flex-start' }}>
              {multiplier > 6 &&
                countRange(Math.min(multiplier - 6)).map(i => (
                  <AssetSvg
                    key={i + multiplier}
                    name={svgName}
                    width={dimens.width / 7}
                    height={dimens.height / 3}
                  />
                ))}
            </View>
          </View>
        )}
        items={answers.map(value => ({ value, component: <Text variant="WRN700">{value}</Text> }))}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'bd0',
  description: 'bd0',
  keywords: ['Times-tables', 'Count', 'Multiplication', 'Ten frame'],
  schema: z.object({
    number: z.number().int().min(5).max(50).step(5),
    orientation: z.enum(['horizontal', 'vertical']),
    rows: z.enum(['columnFirst', 'rowFirst']),
    colors: z.enum(['red', 'yellow', 'blue', 'green']).array().length(2)
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusiveStep(5, 50, 5);
    const orientation = getRandomFromArray(['horizontal', 'vertical'] as const);
    const rows = getRandomFromArray(['columnFirst', 'rowFirst'] as const);
    const colors = getRandomSubArrayFromArray(['red', 'yellow', 'blue', 'green'] as const, 2);

    return { number, orientation, rows, colors };
  },
  Component: props => {
    const {
      question: { number, orientation, rows, colors },
      translate,
      displayMode
    } = props;

    const numberOfTenFrames = Math.ceil(number / 10);
    let isColorOne = false;
    const arrays = [
      ...countRange(numberOfTenFrames - 1).map(() =>
        countRange(10).map(val => {
          if (val === 5 || val === 0) {
            isColorOne = !isColorOne;
          }
          return isColorOne ? colors[0] : colors[1];
        })
      ),
      countRange(10).map(val => {
        const count = (numberOfTenFrames - 1) * 10;
        if (val === 5 || val === 0) {
          isColorOne = !isColorOne;
        }
        return count + val < number ? (isColorOne ? colors[0] : colors[1]) : undefined;
      })
    ];

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.howManyCounters()}
        Content={
          <View
            style={{
              maxWidth: '100%',
              flexWrap: 'wrap',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              columnGap: 40,
              rowGap: 25
            }}
          >
            {countRange(numberOfTenFrames).map(i => (
              <TenFrameLayout
                key={i}
                items={arrays[i]}
                itemOrdering={rows}
                size={displayMode === 'digital' ? 'xsmall' : 'medium'}
                orientation={orientation}
              />
            ))}
          </View>
        }
        pdfDirection="column"
        sentence={'<ans/>'}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        testCorrect={[number.toString()]}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'bd1',
  description: 'bd1',
  keywords: ['Number track', 'Multiplication', 'Count', 'Forwards', 'Backwards', 'Times-tables'],
  schema: z.object({
    startingNumber: z.number().min(0).max(15).step(5),
    answerIndexes: z.array(z.number().min(2).max(7)),
    isForward: z.boolean()
  }),
  simpleGenerator: () => {
    const startingNumber = randomIntegerInclusiveStep(0, 15, 5);
    const numberOfAns = randomIntegerInclusive(3, 5);
    const answerIndexes = randomUniqueIntegersInclusive(2, 7, numberOfAns);
    const isForward = getRandomBoolean();

    return { startingNumber, answerIndexes, isForward };
  },
  Component: ({ question: { startingNumber, answerIndexes, isForward }, translate }) => {
    // Create array to pass to NumberTrack
    const ascendingNumberArray = countRange(8).map(i => startingNumber + i * 5);

    const numberArray = isForward ? ascendingNumberArray : ascendingNumberArray.reverse();

    const ansArray = numberArray
      .filter((_val, i) => answerIndexes.includes(i))
      .map(val => val.toString());

    const stringArray = numberArray.map((val, id) =>
      answerIndexes.includes(id) ? '<ans/>' : val.toLocaleString()
    );

    return (
      <QF14CompleteNumberTrack
        title={translate.instructions.completeNumberTrack()}
        questionHeight={600}
        testCorrect={ansArray}
        boxValues={stringArray}
      />
    );
  },
  questionHeight: 600
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CountIn5s',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question2]
});
export default SmallStep;
