import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  randomUniqueIntegersInclusiveStep,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { arrayHasNoDuplicates, countRange, sortNumberArray } from '../../../../utils/collections';
import QF14CompleteNumberTrack from '../../../../components/question/questionFormats/QF14CompleteNumberTrack';
import { getRandomKs1Name, ks1NameSchema } from '../../../../utils/names';
import { numberEnum } from '../../../../utils/zod';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import { getObjectSvgName } from '../../../../utils/objectsImages';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';
import { View } from 'react-native';
import { AssetSvg } from '../../../../assets/svg';
import Text from '../../../../components/typography/Text';
import { isInRange } from '../../../../utils/matchers';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bgs',
  description: 'bgs',
  keywords: ['Times-tables', 'Count', 'Multiplication'],
  schema: z.object({
    multiplier: z.number().int().min(1).max(12),
    item: z.enum([
      'Apple',
      'Cookie',
      'Flower',
      'Muffin',
      'Pear',
      'Banana',
      'Socks',
      'Marble',
      'Sweet',
      'Balloon',
      'Pencil'
    ]),
    answers: z
      .array(z.number().min(1).max(100))
      .length(3)
      .refine(arrayHasNoDuplicates, 'Answers should have no duplicates'),
    multiple: numberEnum([2, 5, 10])
  }),
  simpleGenerator: () => {
    const multiple = getRandomFromArray([2, 5, 10] as const);
    const multiplier = randomIntegerInclusive(multiple === 10 ? 1 : 2, multiple === 2 ? 12 : 10);

    const item =
      multiple === 2
        ? getRandomFromArray(['Apple', 'Cookie', 'Flower', 'Muffin', 'Socks'] as const)
        : multiple === 5
        ? getRandomFromArray(['Apple', 'Cookie', 'Flower', 'Muffin', 'Pear', 'Banana'] as const)
        : getRandomFromArray(['Marble', 'Sweet', 'Balloon', 'Pencil'] as const);

    const answersA = [multiplier, multiplier * multiple];

    const answersB = rejectionSample(
      () => (getRandomBoolean() ? (multiplier - 1) * multiple : (multiplier + 1) * multiple),
      val => arrayHasNoDuplicates([...answersA, val]) && isInRange(2, 100)(val)
    );

    return { multiplier, item, answers: shuffle([...answersA, answersB]), multiple };
  },
  Component: props => {
    const {
      question: { multiplier, item, answers, multiple },
      translate
    } = props;

    const svgName = (() => {
      if (multiple === 10) {
        switch (item) {
          case 'Marble':
            return 'Base_Ten/Marbles10';
          case 'Sweet':
            return 'Base_Ten/Sweets10';
          case 'Balloon':
            return 'Base_Ten/Balloons10';
          default:
            return 'pencil_packs/Pack_of_pencils_10';
        }
      } else if (multiple === 5) {
        switch (item) {
          case 'Apple':
          case 'Cookie':
          case 'Flower':
            return getObjectSvgName(item, 5);
          case 'Muffin':
            return 'Food_on_a_plate/Muffins_plate_5';
          case 'Pear':
            return 'Pears_in_a_transparent_bag/Pears_in_a_bag_5';
          default:
            return 'Bunches_of_bananas/Bunch_of_bananas_5';
        }
      } else {
        switch (item) {
          case 'Apple':
          case 'Cookie':
          case 'Flower':
            return getObjectSvgName(item, 2);
          case 'Muffin':
            return 'Food_on_a_plate/Muffins_plate_2';
          default:
            return getRandomFromArray(
              [
                'PairedsocksGreen',
                'PairedsocksRed',
                'PairedsocksOrange',
                'PairedsocksPurple',
                'PairedsocksYellow'
              ] as const,
              { random: seededRandom(props.question) }
            );
        }
      }
    })();

    const sentenceLookup = {
      Apple: 'thereAreAnsApplesAltogether',
      Cookie: 'thereAreAnsCookiesAltogether',
      Flower: 'thereAreAnsFlowersAltogether',
      Muffin: 'thereAreAnsMuffinsAltogether',
      Pear: 'thereAreAnsPearsAltogether',
      Banana: 'thereAreAnsBananasAltogether',
      Marble: 'thereAreAnsMarblesAltogether',
      Sweet: 'thereAreAnsSweetsAltogether',
      Balloon: 'thereAreAnsBalloonsAltogether',
      Pencil: 'thereAreAnsPencilsAltogether',
      Socks: 'thereAreAnsSocksAltogether'
    } as const;

    return (
      <QF36ContentAndSentenceDrag
        title={translate.ks1Instructions.dragACardToCompleteTheSentence()}
        pdfTitle={translate.ks1PDFInstructions.useTheCardsToCompleteTheSentence()}
        sentence={translate.ks1AnswerSentences[sentenceLookup[item]]()}
        sentencesStyle={{ alignItems: 'flex-start' }}
        actionPanelVariant="end"
        pdfLayout="itemsTop"
        testCorrect={[multiplier * multiple]}
        Content={({ dimens }) => (
          <View style={{ flexDirection: 'column', gap: 25 }}>
            <View style={{ flexDirection: 'row', gap: 25 }}>
              {countRange(Math.min(multiplier, 6)).map(i => (
                <AssetSvg
                  key={i}
                  name={svgName}
                  width={dimens.width / 7}
                  height={dimens.height / 3}
                />
              ))}
            </View>
            <View style={{ flexDirection: 'row', gap: 25, alignItems: 'flex-start' }}>
              {multiplier > 6 &&
                countRange(Math.min(multiplier - 6)).map(i => (
                  <AssetSvg
                    key={i + multiplier}
                    name={svgName}
                    width={dimens.width / 7}
                    height={dimens.height / 3}
                  />
                ))}
            </View>
          </View>
        )}
        items={answers.map(value => ({ value, component: <Text variant="WRN700">{value}</Text> }))}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'bgt',
  description: 'bgt',
  keywords: ['Number track', 'Multiplication', 'Count', 'Forwards', 'Backwards', 'Times-tables'],
  schema: z.object({
    numberArray: z.number().int().min(0).max(100).array().length(8),
    givenNumbersIndices: z.number().min(0).max(7).array()
  }),
  simpleGenerator: () => {
    const multiplier = getRandomFromArray([2, 5, 10] as const);
    const ordering = getRandomFromArray(['ascending', 'descending'] as const);

    const startingNumber =
      ordering === 'ascending'
        ? randomIntegerInclusiveStep(0, 100 - multiplier * 8, multiplier)
        : randomIntegerInclusiveStep(8 * multiplier, 100, multiplier);

    const numberArray = countRange(8).map(num => {
      return ordering === 'ascending'
        ? startingNumber + num * multiplier
        : startingNumber - num * multiplier;
    });

    const givenNumbersIndices = rejectionSample(
      () => {
        return sortNumberArray(randomUniqueIntegersInclusive(0, 7, randomIntegerInclusive(3, 5)));
      },
      // Check at least two consecutive numbers
      x => numberArray.some((_, i) => x.includes(i) && x.includes(i + 1))
    );

    return { numberArray, givenNumbersIndices };
  },
  Component: ({ question: { numberArray, givenNumbersIndices }, translate }) => {
    const answerArray = numberArray
      .filter((_, index) => !givenNumbersIndices.includes(index))
      .map(el => el.toString());

    const boxValues = numberArray.map((x, i) =>
      givenNumbersIndices.includes(i) ? x.toLocaleString() : `<ans/>`
    );

    return (
      <QF14CompleteNumberTrack
        title={translate.ks1Instructions.completeTheNumberTrack()}
        questionHeight={600}
        testCorrect={answerArray}
        boxValues={boxValues}
      />
    );
  },
  questionHeight: 600
});

const Question3 = newQuestionContent({
  uid: 'bgu',
  description: 'bgu',
  keywords: ['Counting forwards', 'Counting backwards', 'Fraction', 'Mixed number'],
  schema: z.object({
    startingNumber: z.number().int().min(0).max(100),
    order: z.enum(['forwards', 'backwards']),
    name: ks1NameSchema,
    multiplesOf: numberEnum([2, 5, 10]),
    selectables: z
      .array(z.number().int().min(0).max(100))
      .length(6)
      .refine(arrayHasNoDuplicates, 'Should be no duplicate selectables'),
    correctAnswers: z.array(z.number().int().min(0).max(100)).min(2).max(6)
  }),
  simpleGenerator: () => {
    const name = getRandomKs1Name();
    const order = getRandomFromArray(['backwards', 'forwards'] as const);

    const multiplesOf = getRandomFromArray([2, 5, 10] as const);

    const { startingNumber, selectables, correctAnswers } = rejectionSample(
      () => {
        const numberOfCorrectAnswers = randomIntegerInclusive(2, 6);

        const startingNumber =
          multiplesOf === 10
            ? order === 'forwards'
              ? randomIntegerInclusive(0, 100 - (numberOfCorrectAnswers + 1) * 10)
              : randomIntegerInclusive((numberOfCorrectAnswers + 1) * 10, 100)
            : order === 'forwards'
            ? randomIntegerInclusiveStep(0, 100 - numberOfCorrectAnswers * multiplesOf, multiplesOf)
            : randomIntegerInclusiveStep(
                (numberOfCorrectAnswers + 1) * multiplesOf,
                100,
                multiplesOf
              );

        const ones = multiplesOf === 10 ? startingNumber % 10 : null;

        const correctAnswers =
          order === 'forwards'
            ? randomUniqueIntegersInclusiveStep(
                startingNumber + multiplesOf,
                100,
                multiplesOf,
                numberOfCorrectAnswers
              )
            : randomUniqueIntegersInclusiveStep(
                ones ?? 0,
                startingNumber - multiplesOf,
                multiplesOf,
                numberOfCorrectAnswers
              );

        const incorrectOptionsAPlus = correctAnswers.map(num =>
          num !== 100 ? num + 1 : undefined
        );

        const incorrectOptionsBMinus = correctAnswers.map(num => num - 1);

        const incorrectOptionC =
          order === 'forwards' && startingNumber > multiplesOf
            ? startingNumber - multiplesOf
            : undefined;

        const incorrectOptionD =
          order === 'backwards' && multiplesOf + startingNumber >= 100
            ? startingNumber + multiplesOf
            : undefined;

        const incorrectOptionE =
          multiplesOf === 10 && ones !== 0 ? randomIntegerInclusiveStep(1, 100, 10) : undefined;

        const incorrectOptionF = randomIntegerInclusive(0, 100);

        const incorrectOptions = getRandomSubArrayFromArray(
          [
            ...incorrectOptionsAPlus,
            ...incorrectOptionsBMinus,
            incorrectOptionC,
            incorrectOptionD,
            incorrectOptionE,
            incorrectOptionF
          ].filter(el => el !== undefined) as number[],
          6 - numberOfCorrectAnswers
        );

        const selectables = shuffle([...correctAnswers, ...incorrectOptions]);

        return { startingNumber, selectables, correctAnswers };
      },
      ({ selectables }) =>
        arrayHasNoDuplicates(selectables) && selectables.every(num => isInRange(1, 100)(num))
    );

    return {
      startingNumber,
      order,
      name,
      multiplesOf,
      correctAnswers,
      selectables
    };
  },
  Component: props => {
    const {
      question: { startingNumber, order, name, multiplesOf, selectables, correctAnswers },
      translate
    } = props;

    const title =
      order === 'forwards'
        ? translate.ks1Instructions.characterIsCountingForwardsInXFromYSelectNumbers(
            name,
            multiplesOf,
            startingNumber
          )
        : translate.ks1Instructions.characterIsCountingBackwardsInXFromYSelectNumbers(
            name,
            multiplesOf,
            startingNumber
          );
    const pdfTitle =
      order === 'forwards'
        ? translate.ks1PDFInstructions.characterIsCountingForwardsInXFromYTickNumbers(
            name,
            multiplesOf,
            startingNumber
          )
        : translate.ks1PDFInstructions.characterIsCountingBackwardsInXFromYTickNumbers(
            name,
            multiplesOf,
            startingNumber
          );

    return (
      <QF10SelectNumbers
        title={title}
        pdfTitle={pdfTitle}
        testCorrect={correctAnswers}
        items={selectables.map(number => ({
          value: number,
          component: number.toLocaleString()
        }))}
        multiSelect
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CountIn2s5sAnd10s',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question3]
});
export default SmallStep;
