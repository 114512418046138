import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  randomUniqueIntegersInclusiveStep,
  rejectionSample,
  shuffle
} from '../../../../utils/random';
import {
  displayMoney,
  moneyScaledSizes,
  moneyToHighestDenominations,
  penceToPoundsAndPence,
  totalPenceToPoundsAndPence
} from '../../../../utils/money';
import { lessThanGreaterThanOrEqualTo } from '../../../../utils/math';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import Text from 'common/src/components/typography/Text';
import { View } from 'react-native';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { arrayHasNoDuplicates } from '../../../../utils/collections';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bio',
  description: 'bio',
  keywords: [
    'Count',
    'Coins',
    'Greater than',
    'Less than',
    'Equal to',
    'Compare',
    'Pounds',
    'Notes',
    'Coins'
  ],
  schema: z.object({
    moneyInPenceA: z.number().int().min(1).max(2999),
    moneyInPenceB: z.number().int().min(1).max(2999)
  }),
  questionHeight: 1200,
  simpleGenerator: () => {
    const variation = getRandomFromArray([
      'bothAmountsPounds',
      'bothAmountsPence',
      'oneAmountPoundsOnePence',
      'bothAmountPoundsAndPence'
    ] as const);

    const [moneyInPenceA, moneyInPenceB] = (() => {
      switch (variation) {
        case 'bothAmountsPounds':
          return randomUniqueIntegersInclusiveStep(100, 2900, 100, 2);
        case 'bothAmountsPence':
          return randomUniqueIntegersInclusive(1, 99, 2);
        case 'oneAmountPoundsOnePence':
          return shuffle([
            randomIntegerInclusiveStep(100, 2900, 100),
            randomIntegerInclusive(1, 99)
          ]);
        case 'bothAmountPoundsAndPence':
          return randomUniqueIntegersInclusive(1, 2999, 2, { constraint: x => x % 100 !== 0 });
      }
    })();

    return { moneyInPenceA, moneyInPenceB };
  },
  Component: ({ question: { moneyInPenceA, moneyInPenceB }, translate, displayMode }) => {
    const moneyA = totalPenceToPoundsAndPence(moneyInPenceA);
    const moneyB = totalPenceToPoundsAndPence(moneyInPenceB);

    const money = displayMoney(
      [...moneyA, ...moneyB],
      displayMode === 'digital' ? 90 : 130,
      displayMode === 'digital' ? 90 : 130,
      true,
      true
    );

    const [moneyAScaled, moneyBScaled] = [
      money.filter((_, index) => index <= moneyA.length - 1),
      money.filter((_, index) => index >= moneyA.length)
    ];

    return (
      <QF6DragMatchStatements
        title={translate.ks1Instructions.dragACardToCompareTheAmounts()}
        pdfTitle={translate.ks1PDFInstructions.writeLessThanGreaterThanOrEqualSymbolsToCompareTheAmounts()}
        itemVariant="square"
        statements={[
          {
            lhsComponent: (
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  width: displayMode === 'digital' ? 400 : 800,
                  gap: 5
                }}
              >
                {moneyAScaled}
              </View>
            ),
            rhsComponent: (
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  width: displayMode === 'digital' ? 400 : 600,
                  gap: 5
                }}
              >
                {moneyBScaled}
              </View>
            ),
            correctAnswer: lessThanGreaterThanOrEqualTo(moneyInPenceA, moneyInPenceB)
          }
        ]}
        mainPanelStyle={{ alignItems: 'center' }}
        items={['>', '<', '=']}
        moveOrCopy="move"
        questionHeight={1200}
        actionPanelVariant="end"
        pdfLayout="itemsHidden"
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bip',
  description: 'bip',
  keywords: ['Greatest', 'Smallest', 'Money', 'Pounds', 'Pence', 'Coins', 'Notes'],
  schema: z
    .object({
      selectables: z.array(
        z.object({ value: z.number().int().min(1).max(2999), denominations: z.array(z.string()) })
      ),
      isGreatest: z.boolean()
    })
    .refine(
      val => arrayHasNoDuplicates(val.selectables.map(({ value }) => value)),
      'There should be no duplicate values in selectables'
    ),
  simpleGenerator: () => {
    const isGreatest = getRandomBoolean();

    const variation = getRandomFromArray([
      'allAmountsPounds',
      'allAmountsPence',
      'allAmountsPoundsAndPence'
    ] as const);

    const selectables = rejectionSample(
      () => {
        const pence = (() => {
          switch (variation) {
            case 'allAmountsPence': {
              return randomUniqueIntegersInclusive(1, 99, 4);
            }
            case 'allAmountsPounds': {
              return randomUniqueIntegersInclusiveStep(100, 2999, 100, 4);
            }
            case 'allAmountsPoundsAndPence': {
              return randomUniqueIntegersInclusive(101, 2999, 4, {
                constraint: x => x % 100 !== 0
              });
            }
          }
        })();

        const selectables = pence.map(amount => {
          const pounds = Math.floor(amount / 100);
          const pence = amount % 100;

          const amountPence = moneyToHighestDenominations(pence, 'pence');
          const amountPounds = moneyToHighestDenominations(pounds, 'pounds');

          return { value: amount, denominations: [...amountPounds, ...amountPence] };
        });

        return selectables;
      },
      selectables => selectables.every(array => array.denominations.length <= 6)
    );

    return { selectables, isGreatest };
  },
  Component: props => {
    const {
      question: { selectables, isGreatest },
      translate,
      displayMode
    } = props;

    const flattenedMoneyArray = selectables.map(selectable => selectable.denominations).flat();

    // We need to get moneyScales here, so we can use a uniform scale across money in both the items and statements:
    const moneyScales = moneyScaledSizes(flattenedMoneyArray);

    const baseSize = displayMode === 'digital' ? 80 : 100;

    return (
      <QF11SelectImagesUpTo4
        title={
          isGreatest
            ? translate.ks1Instructions.selectTheGreatestAmountOfMoney()
            : translate.ks1Instructions.selectTheSmallestAmountOfMoney()
        }
        pdfTitle={
          isGreatest
            ? translate.ks1PDFInstructions.tickTheGreatestAmountOfMoney()
            : translate.ks1PDFInstructions.tickTheSmallestAmountOfMoney()
        }
        testCorrect={[
          isGreatest
            ? Math.max(...selectables.map(({ value }) => value))
            : Math.min(...selectables.map(({ value }) => value))
        ]}
        numItems={4}
        renderItems={selectables.map(({ value, denominations }) => {
          return {
            value: value,
            component: (
              <View
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  columnGap: 12,
                  rowGap: 12
                }}
              >
                {denominations.map(denom => {
                  return displayMoney(
                    [denom],
                    baseSize * moneyScales[denom],
                    baseSize * moneyScales[denom]
                  );
                })}
              </View>
            )
          };
        })}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'biq',
  description: 'biq',
  keywords: [
    'Count',
    'Coins',
    'Greater than',
    'Less than',
    'Equal to',
    'Compare',
    'Pounds',
    'Notes',
    'Coins'
  ],
  schema: z
    .object({
      penceA: z
        .number()
        .int()
        .min(101)
        .max(1999)
        .refine(money => money % 100 !== 0, 'money should have pence value'),
      penceB: z
        .number()
        .int()
        .min(101)
        .max(1999)
        .refine(money => money % 100 !== 0, 'money should have pence value')
    })
    .refine(val => val.penceA !== val.penceB, 'penceA cannot be equal to penceB'),
  simpleGenerator: () => {
    const variation = getRandomFromArray([
      'differentPoundsSamePence',
      'samePoundsDifferentPence',
      'differentPoundsAndPence'
    ] as const);

    const penceA = randomIntegerInclusive(101, 1999, { constraint: x => x % 100 !== 0 });

    const penceB = randomIntegerInclusive(101, 1999, {
      constraint: x => {
        const poundsA = Math.floor(penceA / 100);
        const penceAb = penceA % 100;

        const poundsB = Math.floor(x / 100);
        const penceBb = x % 100;

        if (penceBb === 0) {
          return false;
        }

        if (variation === 'differentPoundsSamePence') {
          return poundsA !== poundsB && penceAb === penceBb;
        } else if (variation === 'samePoundsDifferentPence') {
          return poundsA === poundsB && penceAb !== penceBb;
        } else {
          return poundsA !== poundsB && penceAb !== penceBb;
        }
      }
    });

    return { penceA, penceB };
  },
  Component: ({ question, translate, displayMode }) => {
    const { penceA, penceB } = question;

    const poundsAndPenceA = penceToPoundsAndPence(penceA);
    const poundsAndPenceB = penceToPoundsAndPence(penceB);

    const statements = [
      {
        lhs: translate.answerSentences.poundsXAndYP(poundsAndPenceA.pounds, poundsAndPenceA.pence),
        rhs: translate.answerSentences.poundsXAndYP(poundsAndPenceB.pounds, poundsAndPenceB.pence),
        answer: lessThanGreaterThanOrEqualTo(penceA, penceB)
      }
    ];

    return (
      <QF6DragMatchStatements
        title={translate.ks1Instructions.dragACardToCompareTheAmounts()}
        pdfTitle={translate.ks1PDFInstructions.writeLessThanGreaterThanOrEqualSymbolsToCompareTheAmounts()}
        itemVariant="square"
        pdfLayout="itemsHidden"
        statements={statements.map(({ lhs, rhs, answer }) => ({
          lhsComponent: (
            <Text
              variant="WRN400"
              style={{ width: displayMode === 'digital' ? 250 : 400, textAlign: 'right' }}
            >
              {lhs}
            </Text>
          ),
          rhsComponent: (
            <Text
              variant="WRN400"
              style={{ width: displayMode === 'digital' ? 250 : 400, textAlign: 'left' }}
            >
              {rhs}
            </Text>
          ),
          correctAnswer: answer
        }))}
        statementStyle={{ justifyContent: 'center' }}
        items={['>', '<', '=']}
        moveOrCopy="move"
        actionPanelVariant="end"
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CompareAmountsOfMoney',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
